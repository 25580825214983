<template>
  <footer class="text-white" style="background: #013854">
    <div class="container">
      <!-- Section de contact -->
      <div class="text-center">
        <div class="h-4"></div>
        <img src="../assets/AP.png" alt="Logo" class="h-16 w-44 mx-auto" />
        <p class="text-xl mt-4 leading-5">
          Connectez-vous à vos auteurs préférés et voyagez à travers les pages
          d'histoires
        </p>
        <h1
          class="text-4xl font-bold border border-gray-200 mt-4 lg:mx-24 mx-16 block lg:hidden opacity-25"
        ></h1>
        <ul
          class="lg:flex lg:space-x-6 lg:text-lg justify-center text-center mt-4"
        >
          <li @click="toggDropdown">
            <router-link
              class="text-yellow-400 hover:text-gray-300 hover:text-yellow-400 hover:underline"
              to="/"
              >Accueil</router-link
            >
          </li>
          <li @click="toggDropdown">
            <router-link
              class="text-yellow-400 hover:text-gray-300 hover:text-yellow-400 hover:underline"
              to="/"
              >A propos</router-link
            >
          </li>
          <li @click="toggDropdown">
            <router-link
              class="text-yellow-400 hover:text-gray-300 hover:text-yellow-400 hover:underline"
              to="/"
              >Nous contacter</router-link
            >
          </li>
          <li class="" @mouseleave="ClotoggleDropdown">
          <div
            class="flex items-center justify-center text-white ml-4 hover:text-yellow-400 hover:underline"
            active-class="text-blue-500"
          >
            <div class="relative inline-block text-left text-lg">
              <button
                @mouseover="toggleDropdown"
                class="flex text-white"
                active-class="text-white"
              >
                Ecrire

                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  class="w-5 h-5 ml-2 -mr-1"
                  viewBox="0 0 20 20"
                  fill="currentColor"
                  aria-hidden="true"
                  @mouseover="toggleDropdown"
                >
                  <path
                    fill-rule="evenodd"
                    d="M6.293 9.293a1 1 0 011.414 0L10 11.586l2.293-2.293a1 1 0 111.414 1.414l-3 3a1 1 0 01-1.414 0l-3-3a1 1 0 010-1.414z"
                    clip-rule="evenodd"
                  />
                </svg>
              </button>
              <div
                v-if="isDropdownOpen"
                id="dropdown-menu"
                class="origin-top-right absolute bottom-6 right-0 mt-1 w-64 rounded-md shadow-lg bg-white ring-1 ring-black ring-opacity-5"
              >
                <div
                  class="p-2"
                  role="menu"
                  aria-orientation="vertical"
                  aria-labelledby="dropdown-button"
                >
                  <div
                    class=""
                    role="menu"
                    aria-orientation="vertical"
                    aria-labelledby="dropdown-button"
                  >
                    <router-link
                      class="text-black hover:text-black flex"
                      active-class="text-slate-950"
                      to="#"
                    >
                      <svg
                        width="16"
                        height="20"
                        viewBox="0 0 16 20"
                        fill="none"
                        xmlns="http://www.w3.org/2000/svg"
                        class="mt-1"
                      >
                        <path
                          fill-rule="evenodd"
                          clip-rule="evenodd"
                          d="M8 0V6.5C8.00002 6.87288 8.13892 7.23239 8.38962 7.50842C8.64032 7.78445 8.98484 7.9572 9.356 7.993L9.5 8H16V18C16.0002 18.5046 15.8096 18.9906 15.4665 19.3605C15.1234 19.7305 14.6532 19.9572 14.15 19.995L14 20H2C1.49542 20.0002 1.00943 19.8096 0.639452 19.4665C0.269471 19.1234 0.0428434 18.6532 0.00500021 18.15L1.00268e-07 18V2C-0.000159579 1.49542 0.190406 1.00943 0.533497 0.639452C0.876588 0.269471 1.34684 0.0428433 1.85 0.00500011L2 0H8ZM8 9.5C7.75507 9.50003 7.51866 9.58996 7.33563 9.75272C7.15259 9.91547 7.03566 10.1397 7.007 10.383L7 10.5V12H5.5C5.24512 12.0003 4.99997 12.0979 4.81463 12.2728C4.6293 12.4478 4.51776 12.687 4.50283 12.9414C4.48789 13.1958 4.57067 13.4464 4.73426 13.6418C4.89785 13.8373 5.1299 13.9629 5.383 13.993L5.5 14H7V15.5C7.00028 15.7549 7.09788 16 7.27285 16.1854C7.44782 16.3707 7.68695 16.4822 7.94139 16.4972C8.19584 16.5121 8.44638 16.4293 8.64183 16.2657C8.83729 16.1021 8.9629 15.8701 8.993 15.617L9 15.5V14H10.5C10.7549 13.9997 11 13.9021 11.1854 13.7272C11.3707 13.5522 11.4822 13.313 11.4972 13.0586C11.5121 12.8042 11.4293 12.5536 11.2657 12.3582C11.1021 12.1627 10.8701 12.0371 10.617 12.007L10.5 12H9V10.5C9 10.2348 8.89464 9.98043 8.70711 9.79289C8.51957 9.60536 8.26522 9.5 8 9.5ZM10 0.043C10.3234 0.11165 10.6247 0.259389 10.877 0.473L11 0.586L15.414 5C15.6483 5.23411 15.8208 5.52275 15.916 5.84L15.956 6H10V0.043Z"
                          fill="#F7D408"
                        />
                      </svg>

                      <span class="ml-2 text-blue-800 font-bold font-mono hover:text-blue-900">Créer une histoire</span>
                    </router-link>
                    <h1
                      class="text-4xl font-bold border border-yellow-400 mt-2"
                    ></h1>
                  </div>
                </div>
                <div class="p-2">
                  <router-link
                    class="text-black hover:text-black"
                    active-class="text-slate-950"
                    to="#"
                  >
                    <span class="ml-2 text-blue-800 font-bold font-mono hover:text-blue-900">Mes histoires</span>
                  </router-link>

                  <h1
                    class="text-4xl font-bold border border-gray-400 mt-4"
                  ></h1>
                </div>
                <div
                  class="p-2"
                  role="menu"
                  aria-orientation="vertical"
                  aria-labelledby="dropdown-button"
                >
                  <div
                    class=""
                    role="menu"
                    aria-orientation="vertical"
                    aria-labelledby="dropdown-button"
                  >
                    <router-link
                      class="text-black hover:text-black"
                      active-class="text-slate-950"
                      to="#"
                    >
                      <span class="ml-2 text-blue-800 font-bold font-mono hover:text-blue-900">Concours d'écriture</span>
                    </router-link>
                  </div>
                  <h1
                    class="text-4xl font-bold border border-yellow-400 mt-4"
                  ></h1>
                </div>
                <div
                  class="py-2 p-2"
                  role="menu"
                  aria-orientation="vertical"
                  aria-labelledby="dropdown-button"
                >
                  <div
                    class=""
                    role="menu"
                    aria-orientation="vertical"
                    aria-labelledby="dropdown-button"
                  >
                    <router-link
                      class="text-black hover:text-black flex"
                      active-class="text-slate-950"
                      to="#"
                    >
                      <svg
                        width="20"
                        height="20"
                        viewBox="0 0 20 20"
                        fill="none"
                        xmlns="http://www.w3.org/2000/svg"
                        class="mt-1"
                      >
                        <path
                          fill-rule="evenodd"
                          clip-rule="evenodd"
                          d="M10 5C10 2.2385 12.2385 0 15 0C17.7615 0 20 2.2385 20 5C20 7.7615 17.7615 10 15 10H10.5C10.3674 10 10.2402 9.94732 10.1464 9.85355C10.0527 9.75979 10 9.63261 10 9.5V5ZM1.889 2.5C1.52082 2.50066 1.1679 2.64721 0.907559 2.90756C0.647214 3.1679 0.500661 3.52082 0.5 3.889C0.5 12.5115 7.4885 19.5 16.111 19.5C16.4792 19.4993 16.8321 19.3528 17.0924 19.0924C17.3528 18.8321 17.4993 18.4792 17.5 18.111V15.009C17.4993 14.6408 17.3528 14.2879 17.0924 14.0276C16.8321 13.7672 16.4792 13.6207 16.111 13.62C15.0872 13.6209 14.0698 13.4587 13.097 13.1395C12.8528 13.0575 12.5905 13.0452 12.3398 13.1041C12.089 13.1631 11.8596 13.2908 11.6775 13.473L9.983 15.1675C7.80932 13.9781 6.02182 12.191 4.832 10.0175L6.527 8.3225C6.70874 8.14201 6.83715 7.91488 6.89811 7.66611C6.95907 7.41733 6.95021 7.15656 6.8725 6.9125L6.8715 6.909L6.8705 6.9055C6.55001 5.93215 6.38746 4.91376 6.389 3.889C6.38834 3.52082 6.24179 3.1679 5.98144 2.90756C5.7211 2.64721 5.36818 2.50066 5 2.5H1.889ZM14.5 7.5V5.5H12.5V4.5H14.5V2.5H15.5V4.5H17.5V5.5H15.5V7.5H14.5Z"
                          fill="#F7D408"
                        />
                      </svg>

                     <span class="ml-2 text-blue-800 font-bold font-mono hover:text-blue-900">Conseils d'écriture</span>
                    </router-link>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </li>
          <li @click="toggDropdown">
            <router-link
              class="text-yellow-400 hover:text-white ml-4 hover:text-yellow-400 hover:underline"
              active-class="text-yellow-400"
              to="#"
              >Espace auteur</router-link
            >
          </li>
        </ul>
        <ul
          class="flex lg:space-x-6 lg:text-xl font-bold justify-center text-center mt-4"
        >
          <li @click="toggDropdown">
            <router-link class="flex text-white hover:text-gray-300" to="/"
              >CGU
              <h1 class="ml-4">.</h1></router-link
            >
          </li>
          <li @click="toggDropdown">
            <router-link class="flex text-white hover:text-gray-300" to="/"
              >FAQ
              <h1 class="ml-4">.</h1></router-link
            >
          </li>
          <li @click="toggDropdown">
            <router-link class="flex text-white hover:text-gray-300" to="/"
              >Politique de confidentialité
            </router-link>
          </li>
        </ul>
        <h1
          class="text-4xl font-bold border border-white mt-4 lg:mx-24 mx-6 opacity-50"
        ></h1>
        <div class="lg:flex lg:mx-24">
          <div class="lg:w-1/3">
            <p class="text-xl text-yellow-400 mt-4 lg:text-left">Newsletter</p>

            <div class="flex mt-2 justify-center md:justify-start">
              <input
                type="text"
                v-model="filterse"
                placeholder="Entrer votre e-mail"
                class="rounded-l-sm border border-blue-400"
              />
              <button
                type="submit"
                class="bg-blue-400 text-white rounded-r px-2 md:px-3 py-0 md:py-1"
              >
                Valider
              </button>
            </div>
          </div>
          <div class="lg:w-1/3">
            <p class="text-xl text-yellow-400 mt-4 leading-5">Suvez-nous</p>
            <div class="flex justify-center mt-2">
              <svg
                xmlns="http://www.w3.org/2000/svg"
                width="32"
                height="32"
                fill="currentColor"
                class="bi bi-linkedin"
                viewBox="0 0 16 16"
              >
                <path
                  d="M0 1.146C0 .513.526 0 1.175 0h13.65C15.474 0 16 .513 16 1.146v13.708c0 .633-.526 1.146-1.175 1.146H1.175C.526 16 0 15.487 0 14.854zm4.943 12.248V6.169H2.542v7.225zm-1.2-8.212c.837 0 1.358-.554 1.358-1.248-.015-.709-.52-1.248-1.342-1.248S2.4 3.226 2.4 3.934c0 .694.521 1.248 1.327 1.248zm4.908 8.212V9.359c0-.216.016-.432.08-.586.173-.431.568-.878 1.232-.878.869 0 1.216.662 1.216 1.634v3.865h2.401V9.25c0-2.22-1.184-3.252-2.764-3.252-1.274 0-1.845.7-2.165 1.193v.025h-.016l.016-.025V6.169h-2.4c.03.678 0 7.225 0 7.225z"
                />
              </svg>
              <svg
                xmlns="http://www.w3.org/2000/svg"
                width="32"
                height="32"
                fill="currentColor"
                class="bi bi-tiktok ml-4"
                viewBox="0 0 16 16"
              >
                <path
                  d="M9 0h1.98c.144.715.54 1.617 1.235 2.512C12.895 3.389 13.797 4 15 4v2c-1.753 0-3.07-.814-4-1.829V11a5 5 0 1 1-5-5v2a3 3 0 1 0 3 3z"
                />
              </svg>
              <svg
                xmlns="http://www.w3.org/2000/svg"
                width="32"
                height="32"
                fill="currentColor"
                class="bi bi-facebook ml-4"
                viewBox="0 0 16 16"
              >
                <path
                  d="M16 8.049c0-4.446-3.582-8.05-8-8.05C3.58 0-.002 3.603-.002 8.05c0 4.017 2.926 7.347 6.75 7.951v-5.625h-2.03V8.05H6.75V6.275c0-2.017 1.195-3.131 3.022-3.131.876 0 1.791.157 1.791.157v1.98h-1.009c-.993 0-1.303.621-1.303 1.258v1.51h2.218l-.354 2.326H9.25V16c3.824-.604 6.75-3.934 6.75-7.951"
                />
              </svg>
            </div>
          </div>
          <h1
            class="text-4xl font-bold border border-white mt-4 lg:mx-24 mx-6 block lg:hidden"
          ></h1>
          <div class=" ">
            <p
              class="lg:text-xl text-sm lg:mt-12 mt-4 lg:text-right whitespace-nowrap lg:font-bold"
            >
              Copyright@ 2024 AfriPlume. Tous droits réservés
            </p>
          </div>
        </div>
        <div class="h-6"></div>
      </div>
    </div>
  </footer>
</template>

<script>
export default {
  name: "Footer",
  data() {
    return {
      isDropdownOpen: false,
      isDropdownOpens: false,
      isMobileMenuOpen: false,
      isDropdownOpenPrfile: false,
      isOpen: false,
      user: "",
      name: "",
      imageUrl: "",
    };
  },
  methods: {
    contactezNous() {
      // Ouvrir un client de messagerie avec l'adresse e-mail pré-remplie
      window.location.href = "mailto:support@csetrainings.com";
    },
    toggleDropdown() {
      this.isDropdownOpen = !this.isDropdownOpen;
      this.isDropdownOpens = false;
      this.isDropdownOpenPrfile = false;
    },
    ClotoggleDropdown(){
      this.isDropdownOpen= false
    }
  },
};
</script>
