<template>
  <div class="">
    <div class="bg-white">
      <div class="">
        <p
          class="p-2 text-center lg:mx-96 lg:text-4xl text-blue-400 mt-9 font-mono font-bold "
        >
          Un monde de <span class="text-yellow-400">secret</span> dans
        </p>

        <p class="font-mono font-bold text-center lg:mx-96 lg:text-4xl text-blue-400">
          la lec<span style="text-decoration-color: rgb(250 204 21); margin-bottom: 2px;" class=" underline">ture</span> vous appelle
          !
        </p>
        <p class="text-gray-600 text-center mt-6">
          une plateforme de lecture et d'écriture en ligne conçus dans le but de
          connecter les écrivains du continent à des lectures
        </p>
      </div>
      <div class="lg:flex lg:mx-16 mx-2 lg:mt-16 mt-12">
        <div class="flex">
          <div
            class="rounded-lg text-gray-900 p-1 py-2 border border-gray-300 bg-white overflow-hidden ring-red-300 focus:ring-4 lg:w-96 flex items-center"
          >
            <svg
              xmlns="http://www.w3.org/2000/svg"
              fill="none"
              viewBox="0 0 24 24"
              stroke-width="1.5"
              stroke="currentColor"
              class="w-6 h-6 ml-4"
            >
              <path
                stroke-linecap="round"
                stroke-linejoin="round"
                d="M21 21l-5.197-5.197m0 0A7.5 7.5 0 105.196 5.196a7.5 7.5 0 0010.607 10.607z"
              />
            </svg>
            <input
              type="text"
              v-model="filter"
              class="rounded-full focus:outline-none w-full bg-white text-gray-900"
              placeholder="Rechercher un livre"
            />
          </div>
          <div
            class="rounded-lg text-gray-900 p-1 py-2 ml-6 border border-blue-400 bg-white overflow-hidden ring-red-300 focus:ring-4 flex items-center py-1"
            @click="toggleDropdownsfils"
          >
            <button
              type="button"
              class="relative flex rounded-full text-sm"
              id="user-menu-button"
              aria-expanded="false"
              aria-haspopup="true"
            >
              <span class="ml-4 text-blue-400">Rebriques</span>
              <svg
                xmlns="http://www.w3.org/2000/svg"
                class="w-5 h-5 ml-2 -mr-1 text-black"
                viewBox="0 0 20 20"
                fill="currentColor"
                aria-hidden="true"
              >
                <path
                  fill-rule="evenodd"
                  d="M6.293 9.293a1 1 0 011.414 0L10 11.586l2.293-2.293a1 1 0 111.414 1.414l-3 3a1 1 0 01-1.414 0l-3-3a1 1 0 010-1.414z"
                  clip-rule="evenodd"
                />
              </svg>
            </button>

            <!-- Dropdown menu -->
            <div
              v-show="isDropdownOpenPrfiles"
              class="absolute z-10 mt-80 rounded-md border-b-4 left-10 lg:left-72 border-yellow-400 bg-white w-72 py-1 shadow-xl ring-1 ring-black ring-opacity-5 focus:outline-none"
              role="menu"
              aria-orientation="vertical"
              aria-labelledby="user-menu-button"
              tabindex="-1"
            >
              <!-- Active: "bg-gray-100", Not Active: "" -->

              <router-link
                class="block px-4 py-2 flex text-sm text-black font-bold"
                role="menuitem"
                tabindex="-1"
                id="user-menu-item-1"
                to="/profils"
              >
                <span class="ml-2 text-blue-800 font-bold font-mono hover:text-blue-900">Meilleurs recommandations</span></router-link
              >

              <router-link
                class="block px-4 py-2 flex text-sm text-black font-bold"
                role="menuitem"
                tabindex="-1"
                id="user-menu-item-1"
                to="/profils"
              >
                <span class="ml-2 text-blue-800 font-bold font-mono hover:text-blue-900">Histoires terminées</span></router-link
              >
              <h1 class="text-xl font-bold border border-gray-400 ml-4"></h1>
              <router-link
                class="block px-4 py-2 flex text-sm text-black font-bold"
                role="menuitem"
                tabindex="-1"
                id="user-menu-item-1"
                to="/profils"
              >
                <span class="ml-2 text-blue-800 font-bold font-mono hover:text-blue-900">L'écrivains de la semaine</span></router-link
              >

              <router-link
                class="block px-4 py-2 flex text-sm text-black font-bold"
                role="menuitem"
                tabindex="-1"
                id="user-menu-item-1"
                to="/profils"
              >
                <span class="ml-2 text-blue-800 font-bold font-mono hover:text-blue-900">Histoires en cours</span></router-link
              >

              <router-link
                class="block px-4 py-2 flex text-sm text-black font-bold"
                role="menuitem"
                tabindex="-1"
                id="user-menu-item-1"
                to="/profils"
              >
               <span class="ml-2 text-blue-800 font-bold font-mono hover:text-blue-900">Tout le monde en parle</span></router-link
              >

              <router-link
                class="block px-4 py-2 flex text-sm text-black font-bold"
                role="menuitem"
                tabindex="-1"
                id="user-menu-item-1"
                to="/profils"
              >
                <span class="ml-2 text-blue-800 font-bold font-mono hover:text-blue-900">Le top de mes genres préferées</span
                ></router-link
              >
              <h1
                class="border border-gray-400 border-solid border-2 ml-4"
              ></h1>
              <router-link
                class="block px-4 py-2 flex text-sm text-black font-bold"
                role="menuitem"
                tabindex="-1"
                id="user-menu-item-1"
                to="/profils"
              >
                <span class="ml-2 text-blue-800 font-bold font-mono hover:text-blue-900">Livre de la semaine</span></router-link
              >
            </div>
          </div>
        </div>
        <div class="flex ml-auto mt-4 lg:mt-0">
          <div
            class="flex items-center justify-center bg-blue-400 hover:bg-blue-800 rounded-lg px-2 text-white hover:text-gray-300 lg:ml-4 whitespace-nowrap"
            active-class="text-blue-500"
          >
            <div class="relative inline-block text-left text-lg">
              <button
                @click="toggleDropdowns"
                class="flex text-white "
                active-class="text-white"
              >
                Genres littéraires

                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  class="w-5 h-5 ml-2 -mr-1"
                  viewBox="0 0 20 20"
                  fill="currentColor"
                  aria-hidden="true"
                >
                  <path
                    fill-rule="evenodd"
                    d="M6.293 9.293a1 1 0 011.414 0L10 11.586l2.293-2.293a1 1 0 111.414 1.414l-3 3a1 1 0 01-1.414 0l-3-3a1 1 0 010-1.414z"
                    clip-rule="evenodd"
                  />
                </svg>
              </button>
              <div
                v-if="isDropdownOpens"
                id="dropdown-menu"
                class="origin-top-right absolute right-0 mt-2 w-48 rounded-md shadow-lg bg-white ring-1 ring-black ring-opacity-5"
              >
                <div
                  class="py-2 p-2"
                  role="menu"
                  aria-orientation="vertical"
                  aria-labelledby="dropdown-button"
                >
                  <div
                    class=""
                    role="menu"
                    aria-orientation="vertical"
                    aria-labelledby="dropdown-button"
                  >
                    <router-link
                      class="text-black hover:text-black"
                      active-class="text-slate-950"
                      to="#"
                    >
                      romance
                    </router-link>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <router-link
            class="text-gray-700 rounded-lg bg-yellow-500 hover:bg-yellow-700 lg:px-8 py-2 text-sm lg:text-xl ml-4 px-2 whitespace-nowrap"
            to="#"
          >
            Commencer à écrire
          </router-link>
        </div>
      </div>
      <div class="h-4"></div>
    </div>
    <div class="" style="background: #001928">
      <div class="flex lg:lg:mx-16 mx-6 mx-6">
        <h1 class="border border-yellow-400 border-9 mt-6"></h1>
        <p class="text-white ml-4 mt-6 lg:text-2xl text-lg font-bold">
          Le livre de la semaine
        </p>
      </div>
      <div
        class="custom-background lg:lg:mx-16 mx-6 mx-6 h-96 mt-9 border border-yellow-400 rounded-lg"
      >
        <div class="">
          <p class="p-2 font-bold ml-6 text-3xl text-gray-100 mt-4">
            Le secret du roi
          </p>
          <div class="flex mt-4">
            <svg
              xmlns="http://www.w3.org/2000/svg"
              fill="none"
              viewBox="0 0 24 24"
              stroke-width="1.5"
              stroke="currentColor"
              class="w-6 h-6 text-white ml-6"
            >
              <path
                stroke-linecap="round"
                stroke-linejoin="round"
                d="M2.036 12.322a1.012 1.012 0 0 1 0-.639C3.423 7.51 7.36 4.5 12 4.5c4.638 0 8.573 3.007 9.963 7.178.07.207.07.431 0 .639C20.577 16.49 16.64 19.5 12 19.5c-4.638 0-8.573-3.007-9.963-7.178Z"
              />
              <path
                stroke-linecap="round"
                stroke-linejoin="round"
                d="M15 12a3 3 0 1 1-6 0 3 3 0 0 1 6 0Z"
              />
            </svg>
            <span class="text-white ml-3">400.00K</span>
            <button
              class="border border-yellow-400 text-yellow-400 px-4 py-1 rounded-full ml-4"
            >
              <spam class="text-sm">Humour</spam>
            </button>
          </div>
        </div>
        <div
          class="flex w-44 fond-bold rounded-full px-3 py-2 mr-4 mt-32 lg:mt-48 ml-auto text-yellow-400 border border-yellow-400 hover:text-gray-300 ml-4"
          active-class="text-blue-500"
        >
          <div class="relative inline-block text-left text-lg">
            <button
              class="flex text-yellow-400 font-bold hover:text-gray-300"
              active-class="text-white"
            >
              En savoir plus

              <svg
                xmlns="http://www.w3.org/2000/svg"
                fill="none"
                viewBox="0 0 24 24"
                stroke-width="1.5"
                stroke="currentColor"
                class="w-6 h-6"
              >
                <path
                  stroke-linecap="round"
                  stroke-linejoin="round"
                  d="M17.25 8.25 21 12m0 0-3.75 3.75M21 12H3"
                />
              </svg>
            </button>
          </div>
        </div>
      </div>
      <div class="flex mx-6">
        <h1 class="border border-yellow-400 border-9 mt-6"></h1>
        <p
          class="text-white ml-4 mt-6 lg:lg:text-2xl text-lg text-lg font-bold"
        >
          Meilleurs recommandations
        </p>
      </div>
      <div class="mt-4 lg:lg:mx-16 mx-6 mx-6 overflow-x-auto">
        <div class="flex space-x-4">
          <div class="transition-shadow duration-300 text-gray-700 rounded">
            <div class="relative">
              <router-link to="/romandetail"
                ><div class="overflow-hidden">
                  <img
                    src="../assets/17117.jpeg"
                    class="object-cover w-full rounded transform transition duration-300 hover:scale-105"
                    alt=""
                  /></div
              ></router-link>
              <span
                class="absolute top-0 left-0 text-xl font-bold text-white px-2 py-1 rounded-full"
                ><svg
                  xmlns="http://www.w3.org/2000/svg"
                  fill="none"
                  viewBox="0 0 24 24"
                  stroke-width="1.5"
                  stroke="currentColor"
                  class="w-6 h-6 ml-4 mt-2"
                >
                  <path
                    stroke-linecap="round"
                    stroke-linejoin="round"
                    d="M21 8.25c0-2.485-2.099-4.5-4.688-4.5-1.935 0-3.597 1.126-4.312 2.733-.715-1.607-2.377-2.733-4.313-2.733C5.1 3.75 3 5.765 3 8.25c0 7.22 9 12 9 12s9-4.78 9-12Z"
                  />
                </svg>
              </span>
            </div>
            <div class="py-5">
              <p class="lg:text-2xl text-lg font-bold leading-5 text-blue-400">
                Le secret du rois
              </p>
              <p class="text-xl mt-2 leading-5 text-gray-600">
                Cette histoire divulge, en effet le manque ou la mefiance entre
                ... <span class="text-blue-400">Lire la suite</span>
              </p>
              <div class="flex mt-2">
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  fill="none"
                  viewBox="0 0 24 24"
                  stroke-width="1.5"
                  stroke="currentColor"
                  class="w-6 h-6 text-gray-200"
                >
                  <path
                    stroke-linecap="round"
                    stroke-linejoin="round"
                    d="M2.036 12.322a1.012 1.012 0 0 1 0-.639C3.423 7.51 7.36 4.5 12 4.5c4.638 0 8.573 3.007 9.963 7.178.07.207.07.431 0 .639C20.577 16.49 16.64 19.5 12 19.5c-4.638 0-8.573-3.007-9.963-7.178Z"
                  />
                  <path
                    stroke-linecap="round"
                    stroke-linejoin="round"
                    d="M15 12a3 3 0 1 1-6 0 3 3 0 0 1 6 0Z"
                  />
                </svg>
                <span class="text-gray-200 ml-3">400.00K</span>
                <button
                  class="text-yellow-400 font-bold px-4 rounded-full ml-4 border border-yellow-400"
                >
                  <spam class="text-sm">Humour</spam>
                </button>
              </div>
            </div>
          </div>
          <div class="transition-shadow duration-300 text-gray-700 rounded">
            <div class="relative">
              <router-link to="/romandetail"
                ><div class="overflow-hidden">
                  <img
                    src="../assets/17117.jpeg"
                    class="object-cover w-full rounded transform transition duration-300 hover:scale-105"
                    alt=""
                  /></div
              ></router-link>
              <span
                class="absolute top-0 left-0 text-xl font-bold text-white px-2 py-1 rounded-full"
                ><svg
                  xmlns="http://www.w3.org/2000/svg"
                  fill="none"
                  viewBox="0 0 24 24"
                  stroke-width="1.5"
                  stroke="currentColor"
                  class="w-6 h-6 ml-4 mt-2"
                >
                  <path
                    stroke-linecap="round"
                    stroke-linejoin="round"
                    d="M21 8.25c0-2.485-2.099-4.5-4.688-4.5-1.935 0-3.597 1.126-4.312 2.733-.715-1.607-2.377-2.733-4.313-2.733C5.1 3.75 3 5.765 3 8.25c0 7.22 9 12 9 12s9-4.78 9-12Z"
                  />
                </svg>
              </span>
            </div>
            <div class="py-5">
              <p class="lg:text-2xl text-lg font-bold leading-5 text-blue-400">
                Le secret du rois
              </p>
              <p class="text-xl mt-2 leading-5 text-gray-600">
                Cette histoire divulge, en effet le manque ou la mefiance entre
                ... <span class="text-blue-400">Lire la suite</span>
              </p>
              <div class="flex mt-2">
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  fill="none"
                  viewBox="0 0 24 24"
                  stroke-width="1.5"
                  stroke="currentColor"
                  class="w-6 h-6 text-gray-200"
                >
                  <path
                    stroke-linecap="round"
                    stroke-linejoin="round"
                    d="M2.036 12.322a1.012 1.012 0 0 1 0-.639C3.423 7.51 7.36 4.5 12 4.5c4.638 0 8.573 3.007 9.963 7.178.07.207.07.431 0 .639C20.577 16.49 16.64 19.5 12 19.5c-4.638 0-8.573-3.007-9.963-7.178Z"
                  />
                  <path
                    stroke-linecap="round"
                    stroke-linejoin="round"
                    d="M15 12a3 3 0 1 1-6 0 3 3 0 0 1 6 0Z"
                  />
                </svg>
                <span class="text-gray-200 ml-3">400.00K</span>
                <button
                  class="text-yellow-400 font-bold px-4 rounded-full ml-4 border border-yellow-400"
                >
                  <spam class="text-sm">Humour</spam>
                </button>
              </div>
            </div>
          </div>
          <div class="transition-shadow duration-300 text-gray-700 rounded">
            <div class="relative">
              <router-link to="/romandetail"
                ><div class="overflow-hidden">
                  <img
                    src="../assets/17117.jpeg"
                    class="object-cover w-full rounded transform transition duration-300 hover:scale-105"
                    alt=""
                  /></div
              ></router-link>
              <span
                class="absolute top-0 left-0 text-xl font-bold text-white px-2 py-1 rounded-full"
                ><svg
                  xmlns="http://www.w3.org/2000/svg"
                  fill="none"
                  viewBox="0 0 24 24"
                  stroke-width="1.5"
                  stroke="currentColor"
                  class="w-6 h-6 ml-4 mt-2"
                >
                  <path
                    stroke-linecap="round"
                    stroke-linejoin="round"
                    d="M21 8.25c0-2.485-2.099-4.5-4.688-4.5-1.935 0-3.597 1.126-4.312 2.733-.715-1.607-2.377-2.733-4.313-2.733C5.1 3.75 3 5.765 3 8.25c0 7.22 9 12 9 12s9-4.78 9-12Z"
                  />
                </svg>
              </span>
            </div>
            <div class="py-5">
              <p class="lg:text-2xl text-lg font-bold leading-5 text-blue-400">
                Le secret du rois
              </p>
              <p class="text-xl mt-2 leading-5 text-gray-600">
                Cette histoire divulge, en effet le manque ou la mefiance entre
                ... <span class="text-blue-400">Lire la suite</span>
              </p>
              <div class="flex mt-2">
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  fill="none"
                  viewBox="0 0 24 24"
                  stroke-width="1.5"
                  stroke="currentColor"
                  class="w-6 h-6 text-gray-200"
                >
                  <path
                    stroke-linecap="round"
                    stroke-linejoin="round"
                    d="M2.036 12.322a1.012 1.012 0 0 1 0-.639C3.423 7.51 7.36 4.5 12 4.5c4.638 0 8.573 3.007 9.963 7.178.07.207.07.431 0 .639C20.577 16.49 16.64 19.5 12 19.5c-4.638 0-8.573-3.007-9.963-7.178Z"
                  />
                  <path
                    stroke-linecap="round"
                    stroke-linejoin="round"
                    d="M15 12a3 3 0 1 1-6 0 3 3 0 0 1 6 0Z"
                  />
                </svg>
                <span class="text-gray-200 ml-3">400.00K</span>
                <button
                  class="text-yellow-400 font-bold px-4 rounded-full ml-4 border border-yellow-400"
                >
                  <spam class="text-sm">Humour</spam>
                </button>
              </div>
            </div>
          </div>
          <div class="transition-shadow duration-300 text-gray-700 rounded">
            <div class="relative">
              <router-link to="/romandetail"
                ><div class="overflow-hidden">
                  <img
                    src="../assets/17117.jpeg"
                    class="object-cover w-full rounded transform transition duration-300 hover:scale-105"
                    alt=""
                  /></div
              ></router-link>
              <span
                class="absolute top-0 left-0 text-xl font-bold text-white px-2 py-1 rounded-full"
                ><svg
                  xmlns="http://www.w3.org/2000/svg"
                  fill="none"
                  viewBox="0 0 24 24"
                  stroke-width="1.5"
                  stroke="currentColor"
                  class="w-6 h-6 ml-4 mt-2"
                >
                  <path
                    stroke-linecap="round"
                    stroke-linejoin="round"
                    d="M21 8.25c0-2.485-2.099-4.5-4.688-4.5-1.935 0-3.597 1.126-4.312 2.733-.715-1.607-2.377-2.733-4.313-2.733C5.1 3.75 3 5.765 3 8.25c0 7.22 9 12 9 12s9-4.78 9-12Z"
                  />
                </svg>
              </span>
            </div>
            <div class="py-5">
              <p class="lg:text-2xl text-lg font-bold leading-5 text-blue-400">
                Le secret du rois
              </p>
              <p class="text-xl mt-2 leading-5 text-gray-600">
                Cette histoire divulge, en effet le manque ou la mefiance entre
                ... <span class="text-blue-400">Lire la suite</span>
              </p>
              <div class="flex mt-2">
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  fill="none"
                  viewBox="0 0 24 24"
                  stroke-width="1.5"
                  stroke="currentColor"
                  class="w-6 h-6 text-gray-200"
                >
                  <path
                    stroke-linecap="round"
                    stroke-linejoin="round"
                    d="M2.036 12.322a1.012 1.012 0 0 1 0-.639C3.423 7.51 7.36 4.5 12 4.5c4.638 0 8.573 3.007 9.963 7.178.07.207.07.431 0 .639C20.577 16.49 16.64 19.5 12 19.5c-4.638 0-8.573-3.007-9.963-7.178Z"
                  />
                  <path
                    stroke-linecap="round"
                    stroke-linejoin="round"
                    d="M15 12a3 3 0 1 1-6 0 3 3 0 0 1 6 0Z"
                  />
                </svg>
                <span class="text-gray-200 ml-3">400.00K</span>
                <button
                  class="text-yellow-400 font-bold px-4 rounded-full ml-4 border border-yellow-400"
                >
                  <spam class="text-sm">Humour</spam>
                </button>
              </div>
            </div>
          </div>
          <div class="transition-shadow duration-300 text-gray-700 rounded">
            <div class="relative">
              <router-link to="/romandetail"
                ><div class="overflow-hidden">
                  <img
                    src="../assets/17117.jpeg"
                    class="object-cover w-full rounded transform transition duration-300 hover:scale-105"
                    alt=""
                  /></div
              ></router-link>
              <span
                class="absolute top-0 left-0 text-xl font-bold text-white px-2 py-1 rounded-full"
                ><svg
                  xmlns="http://www.w3.org/2000/svg"
                  fill="none"
                  viewBox="0 0 24 24"
                  stroke-width="1.5"
                  stroke="currentColor"
                  class="w-6 h-6 ml-4 mt-2"
                >
                  <path
                    stroke-linecap="round"
                    stroke-linejoin="round"
                    d="M21 8.25c0-2.485-2.099-4.5-4.688-4.5-1.935 0-3.597 1.126-4.312 2.733-.715-1.607-2.377-2.733-4.313-2.733C5.1 3.75 3 5.765 3 8.25c0 7.22 9 12 9 12s9-4.78 9-12Z"
                  />
                </svg>
              </span>
            </div>
            <div class="py-5">
              <p class="lg:text-2xl text-lg font-bold leading-5 text-blue-400">
                Le secret du rois
              </p>
              <p class="text-xl mt-2 leading-5 text-gray-600">
                Cette histoire divulge, en effet le manque ou la mefiance entre
                ... <span class="text-blue-400">Lire la suite</span>
              </p>
              <div class="flex mt-2">
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  fill="none"
                  viewBox="0 0 24 24"
                  stroke-width="1.5"
                  stroke="currentColor"
                  class="w-6 h-6 text-gray-200"
                >
                  <path
                    stroke-linecap="round"
                    stroke-linejoin="round"
                    d="M2.036 12.322a1.012 1.012 0 0 1 0-.639C3.423 7.51 7.36 4.5 12 4.5c4.638 0 8.573 3.007 9.963 7.178.07.207.07.431 0 .639C20.577 16.49 16.64 19.5 12 19.5c-4.638 0-8.573-3.007-9.963-7.178Z"
                  />
                  <path
                    stroke-linecap="round"
                    stroke-linejoin="round"
                    d="M15 12a3 3 0 1 1-6 0 3 3 0 0 1 6 0Z"
                  />
                </svg>
                <span class="text-gray-200 ml-3">400.00K</span>
                <button
                  class="text-yellow-400 font-bold px-4 rounded-full ml-4 border border-yellow-400"
                >
                  <spam class="text-sm">Humour</spam>
                </button>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div
        class="flex w-44 fond-bold rounded-full px-3 py-2 mx-auto mt-6 ml-auto text-white hover:text-gray-300"
        active-class="text-blue-500"
      >
        <div class="relative inline-block text-left text-lg">
          <button
            class="flex text-white hover:text-gray-300"
            active-class="text-white"
          >
            Afficher plus

            <svg
              xmlns="http://www.w3.org/2000/svg"
              class="w-6 h-6 ml-2"
              viewBox="0 0 20 20"
              fill="currentColor"
              aria-hidden="true"
            >
              <path
                fill-rule="evenodd"
                d="M6.293 9.293a1 1 0 011.414 0L10 11.586l2.293-2.293a1 1 0 111.414 1.414l-3 3a1 1 0 01-1.414 0l-3-3a1 1 0 010-1.414z"
                clip-rule="evenodd"
              />
            </svg>
          </button>
        </div>
      </div>
      <div class="flex mx-6">
        <h1 class="border border-yellow-400 border-9 mt-6"></h1>
        <p
          class="text-white ml-4 mt-6 lg:lg:text-2xl text-lg text-lg font-bold"
        >
          Histoires terminées
        </p>
      </div>
      <div class="mt-4 lg:lg:mx-16 mx-6 mx-6 overflow-x-auto">
        <div class="flex space-x-4">
          <div class="transition-shadow duration-300 text-gray-700 rounded">
            <div class="relative">
              <router-link to="/romandetail"
                ><div class="overflow-hidden">
                  <img
                    src="../assets/17117.jpeg"
                    class="object-cover w-full rounded transform transition duration-300 hover:scale-105"
                    alt=""
                  /></div
              ></router-link>
              <span
                class="absolute top-0 left-0 text-xl font-bold text-white px-2 py-1 rounded-full"
                ><svg
                  xmlns="http://www.w3.org/2000/svg"
                  fill="none"
                  viewBox="0 0 24 24"
                  stroke-width="1.5"
                  stroke="currentColor"
                  class="w-6 h-6 ml-4 mt-2"
                >
                  <path
                    stroke-linecap="round"
                    stroke-linejoin="round"
                    d="M21 8.25c0-2.485-2.099-4.5-4.688-4.5-1.935 0-3.597 1.126-4.312 2.733-.715-1.607-2.377-2.733-4.313-2.733C5.1 3.75 3 5.765 3 8.25c0 7.22 9 12 9 12s9-4.78 9-12Z"
                  />
                </svg>
              </span>
            </div>
            <div class="py-5">
              <p class="lg:text-2xl text-lg font-bold leading-5 text-blue-400">
                Le secret du rois
              </p>
              <p class="text-xl mt-2 leading-5 text-gray-600">
                Cette histoire divulge, en effet le manque ou la mefiance entre
                ... <span class="text-blue-400">Lire la suite</span>
              </p>
              <div class="flex mt-2">
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  fill="none"
                  viewBox="0 0 24 24"
                  stroke-width="1.5"
                  stroke="currentColor"
                  class="w-6 h-6 text-gray-200"
                >
                  <path
                    stroke-linecap="round"
                    stroke-linejoin="round"
                    d="M2.036 12.322a1.012 1.012 0 0 1 0-.639C3.423 7.51 7.36 4.5 12 4.5c4.638 0 8.573 3.007 9.963 7.178.07.207.07.431 0 .639C20.577 16.49 16.64 19.5 12 19.5c-4.638 0-8.573-3.007-9.963-7.178Z"
                  />
                  <path
                    stroke-linecap="round"
                    stroke-linejoin="round"
                    d="M15 12a3 3 0 1 1-6 0 3 3 0 0 1 6 0Z"
                  />
                </svg>
                <span class="text-gray-200 ml-3">400.00K</span>
                <button
                  class="text-yellow-400 font-bold px-4 rounded-full ml-4 border border-yellow-400"
                >
                  <spam class="text-sm">Humour</spam>
                </button>
              </div>
            </div>
          </div>
          <div class="transition-shadow duration-300 text-gray-700 rounded">
            <div class="relative">
              <router-link to="/romandetail"
                ><div class="overflow-hidden">
                  <img
                    src="../assets/17117.jpeg"
                    class="object-cover w-full rounded transform transition duration-300 hover:scale-105"
                    alt=""
                  /></div
              ></router-link>
              <span
                class="absolute top-0 left-0 text-xl font-bold text-white px-2 py-1 rounded-full"
                ><svg
                  xmlns="http://www.w3.org/2000/svg"
                  fill="none"
                  viewBox="0 0 24 24"
                  stroke-width="1.5"
                  stroke="currentColor"
                  class="w-6 h-6 ml-4 mt-2"
                >
                  <path
                    stroke-linecap="round"
                    stroke-linejoin="round"
                    d="M21 8.25c0-2.485-2.099-4.5-4.688-4.5-1.935 0-3.597 1.126-4.312 2.733-.715-1.607-2.377-2.733-4.313-2.733C5.1 3.75 3 5.765 3 8.25c0 7.22 9 12 9 12s9-4.78 9-12Z"
                  />
                </svg>
              </span>
            </div>
            <div class="py-5">
              <p class="lg:text-2xl text-lg font-bold leading-5 text-blue-400">
                Le secret du rois
              </p>
              <p class="text-xl mt-2 leading-5 text-gray-600">
                Cette histoire divulge, en effet le manque ou la mefiance entre
                ... <span class="text-blue-400">Lire la suite</span>
              </p>
              <div class="flex mt-2">
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  fill="none"
                  viewBox="0 0 24 24"
                  stroke-width="1.5"
                  stroke="currentColor"
                  class="w-6 h-6 text-gray-200"
                >
                  <path
                    stroke-linecap="round"
                    stroke-linejoin="round"
                    d="M2.036 12.322a1.012 1.012 0 0 1 0-.639C3.423 7.51 7.36 4.5 12 4.5c4.638 0 8.573 3.007 9.963 7.178.07.207.07.431 0 .639C20.577 16.49 16.64 19.5 12 19.5c-4.638 0-8.573-3.007-9.963-7.178Z"
                  />
                  <path
                    stroke-linecap="round"
                    stroke-linejoin="round"
                    d="M15 12a3 3 0 1 1-6 0 3 3 0 0 1 6 0Z"
                  />
                </svg>
                <span class="text-gray-200 ml-3">400.00K</span>
                <button
                  class="text-yellow-400 font-bold px-4 rounded-full ml-4 border border-yellow-400"
                >
                  <spam class="text-sm">Humour</spam>
                </button>
              </div>
            </div>
          </div>
          <div class="transition-shadow duration-300 text-gray-700 rounded">
            <div class="relative">
              <router-link to="/romandetail"
                ><div class="overflow-hidden">
                  <img
                    src="../assets/17117.jpeg"
                    class="object-cover w-full rounded transform transition duration-300 hover:scale-105"
                    alt=""
                  /></div
              ></router-link>
              <span
                class="absolute top-0 left-0 text-xl font-bold text-white px-2 py-1 rounded-full"
                ><svg
                  xmlns="http://www.w3.org/2000/svg"
                  fill="none"
                  viewBox="0 0 24 24"
                  stroke-width="1.5"
                  stroke="currentColor"
                  class="w-6 h-6 ml-4 mt-2"
                >
                  <path
                    stroke-linecap="round"
                    stroke-linejoin="round"
                    d="M21 8.25c0-2.485-2.099-4.5-4.688-4.5-1.935 0-3.597 1.126-4.312 2.733-.715-1.607-2.377-2.733-4.313-2.733C5.1 3.75 3 5.765 3 8.25c0 7.22 9 12 9 12s9-4.78 9-12Z"
                  />
                </svg>
              </span>
            </div>
            <div class="py-5">
              <p class="lg:text-2xl text-lg font-bold leading-5 text-blue-400">
                Le secret du rois
              </p>
              <p class="text-xl mt-2 leading-5 text-gray-600">
                Cette histoire divulge, en effet le manque ou la mefiance entre
                ... <span class="text-blue-400">Lire la suite</span>
              </p>
              <div class="flex mt-2">
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  fill="none"
                  viewBox="0 0 24 24"
                  stroke-width="1.5"
                  stroke="currentColor"
                  class="w-6 h-6 text-gray-200"
                >
                  <path
                    stroke-linecap="round"
                    stroke-linejoin="round"
                    d="M2.036 12.322a1.012 1.012 0 0 1 0-.639C3.423 7.51 7.36 4.5 12 4.5c4.638 0 8.573 3.007 9.963 7.178.07.207.07.431 0 .639C20.577 16.49 16.64 19.5 12 19.5c-4.638 0-8.573-3.007-9.963-7.178Z"
                  />
                  <path
                    stroke-linecap="round"
                    stroke-linejoin="round"
                    d="M15 12a3 3 0 1 1-6 0 3 3 0 0 1 6 0Z"
                  />
                </svg>
                <span class="text-gray-200 ml-3">400.00K</span>
                <button
                  class="text-yellow-400 font-bold px-4 rounded-full ml-4 border border-yellow-400"
                >
                  <spam class="text-sm">Humour</spam>
                </button>
              </div>
            </div>
          </div>
          <div class="transition-shadow duration-300 text-gray-700 rounded">
            <div class="relative">
              <router-link to="/romandetail"
                ><div class="overflow-hidden">
                  <img
                    src="../assets/17117.jpeg"
                    class="object-cover w-full rounded transform transition duration-300 hover:scale-105"
                    alt=""
                  /></div
              ></router-link>
              <span
                class="absolute top-0 left-0 text-xl font-bold text-white px-2 py-1 rounded-full"
                ><svg
                  xmlns="http://www.w3.org/2000/svg"
                  fill="none"
                  viewBox="0 0 24 24"
                  stroke-width="1.5"
                  stroke="currentColor"
                  class="w-6 h-6 ml-4 mt-2"
                >
                  <path
                    stroke-linecap="round"
                    stroke-linejoin="round"
                    d="M21 8.25c0-2.485-2.099-4.5-4.688-4.5-1.935 0-3.597 1.126-4.312 2.733-.715-1.607-2.377-2.733-4.313-2.733C5.1 3.75 3 5.765 3 8.25c0 7.22 9 12 9 12s9-4.78 9-12Z"
                  />
                </svg>
              </span>
            </div>
            <div class="py-5">
              <p class="lg:text-2xl text-lg font-bold leading-5 text-blue-400">
                Le secret du rois
              </p>
              <p class="text-xl mt-2 leading-5 text-gray-600">
                Cette histoire divulge, en effet le manque ou la mefiance entre
                ... <span class="text-blue-400">Lire la suite</span>
              </p>
              <div class="flex mt-2">
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  fill="none"
                  viewBox="0 0 24 24"
                  stroke-width="1.5"
                  stroke="currentColor"
                  class="w-6 h-6 text-gray-200"
                >
                  <path
                    stroke-linecap="round"
                    stroke-linejoin="round"
                    d="M2.036 12.322a1.012 1.012 0 0 1 0-.639C3.423 7.51 7.36 4.5 12 4.5c4.638 0 8.573 3.007 9.963 7.178.07.207.07.431 0 .639C20.577 16.49 16.64 19.5 12 19.5c-4.638 0-8.573-3.007-9.963-7.178Z"
                  />
                  <path
                    stroke-linecap="round"
                    stroke-linejoin="round"
                    d="M15 12a3 3 0 1 1-6 0 3 3 0 0 1 6 0Z"
                  />
                </svg>
                <span class="text-gray-200 ml-3">400.00K</span>
                <button
                  class="text-yellow-400 font-bold px-4 rounded-full ml-4 border border-yellow-400"
                >
                  <spam class="text-sm">Humour</spam>
                </button>
              </div>
            </div>
          </div>
          <div class="transition-shadow duration-300 text-gray-700 rounded">
            <div class="relative">
              <router-link to="/romandetail"
                ><div class="overflow-hidden">
                  <img
                    src="../assets/17117.jpeg"
                    class="object-cover w-full rounded transform transition duration-300 hover:scale-105"
                    alt=""
                  /></div
              ></router-link>
              <span
                class="absolute top-0 left-0 text-xl font-bold text-white px-2 py-1 rounded-full"
                ><svg
                  xmlns="http://www.w3.org/2000/svg"
                  fill="none"
                  viewBox="0 0 24 24"
                  stroke-width="1.5"
                  stroke="currentColor"
                  class="w-6 h-6 ml-4 mt-2"
                >
                  <path
                    stroke-linecap="round"
                    stroke-linejoin="round"
                    d="M21 8.25c0-2.485-2.099-4.5-4.688-4.5-1.935 0-3.597 1.126-4.312 2.733-.715-1.607-2.377-2.733-4.313-2.733C5.1 3.75 3 5.765 3 8.25c0 7.22 9 12 9 12s9-4.78 9-12Z"
                  />
                </svg>
              </span>
            </div>
            <div class="py-5">
              <p class="lg:text-2xl text-lg font-bold leading-5 text-blue-400">
                Le secret du rois
              </p>
              <p class="text-xl mt-2 leading-5 text-gray-600">
                Cette histoire divulge, en effet le manque ou la mefiance entre
                ... <span class="text-blue-400">Lire la suite</span>
              </p>
              <div class="flex mt-2">
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  fill="none"
                  viewBox="0 0 24 24"
                  stroke-width="1.5"
                  stroke="currentColor"
                  class="w-6 h-6 text-gray-200"
                >
                  <path
                    stroke-linecap="round"
                    stroke-linejoin="round"
                    d="M2.036 12.322a1.012 1.012 0 0 1 0-.639C3.423 7.51 7.36 4.5 12 4.5c4.638 0 8.573 3.007 9.963 7.178.07.207.07.431 0 .639C20.577 16.49 16.64 19.5 12 19.5c-4.638 0-8.573-3.007-9.963-7.178Z"
                  />
                  <path
                    stroke-linecap="round"
                    stroke-linejoin="round"
                    d="M15 12a3 3 0 1 1-6 0 3 3 0 0 1 6 0Z"
                  />
                </svg>
                <span class="text-gray-200 ml-3">400.00K</span>
                <button
                  class="text-yellow-400 font-bold px-4 rounded-full ml-4 border border-yellow-400"
                >
                  <spam class="text-sm">Humour</spam>
                </button>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div
        class="flex w-44 fond-bold rounded-full px-3 py-2 mx-auto mt-6 ml-auto text-white hover:text-gray-300"
        active-class="text-blue-500"
      >
        <div class="relative inline-block text-left text-lg">
          <button
            class="flex text-white hover:text-gray-300"
            active-class="text-white"
          >
            Afficher plus

            <svg
              xmlns="http://www.w3.org/2000/svg"
              class="w-6 h-6 ml-2"
              viewBox="0 0 20 20"
              fill="currentColor"
              aria-hidden="true"
            >
              <path
                fill-rule="evenodd"
                d="M6.293 9.293a1 1 0 011.414 0L10 11.586l2.293-2.293a1 1 0 111.414 1.414l-3 3a1 1 0 01-1.414 0l-3-3a1 1 0 010-1.414z"
                clip-rule="evenodd"
              />
            </svg>
          </button>
        </div>
      </div>
      <div class="bg-white relative">
        <div class="flex lg:mx-16 mx-6">
          <h1 class="border border-yellow-400 border-9 mt-6"></h1>
          <p class="text-black ml-4 mt-6 lg:text-2xl text-lg font-bold">
            Le livre de la semaine
          </p>
        </div>
        <div class="lg:mx-16 mx-6 mt-9 rounded-lg relative">
          <div class="group rounded-lg">
            <img
              class="w-full h-96 rounded-lg border-b-8 border-transparent group-hover:border-yellow-400 transition-border duration-300"
              src="../assets/background.jpg"
              alt=""
            />
            <div class="h-9"></div>
            <!-- Texte superposé sur l'image -->
            <div
              class="absolute top-20 lg:left-20 left-10 right-0 bottom-0 opacity-0 group-hover:opacity-100 transition-opacity duration-300"
            >
              <p class="text-white text-2xl lg:text-6xl font-bold">Gerald Ho</p>
              <p class="text-white lg:text-2xl text-lg font-bold mt-2">
                Ecrivain
              </p>
              <div
                class="flex w-44 fond-bold rounded-full px-3 py-2 mr-4 mt-24 lg:mt-32 ml-auto text-yellow-400 border border-yellow-400 hover:text-gray-300 ml-4"
                active-class="text-blue-500"
              >
                <div class="relative inline-block text-left text-lg">
                  <button
                    class="flex text-yellow-400 font-bold"
                    active-class="text-white"
                  >
                    En savoir plus

                    <svg
                      xmlns="http://www.w3.org/2000/svg"
                      fill="none"
                      viewBox="0 0 24 24"
                      stroke-width="1.5"
                      stroke="currentColor"
                      class="w-6 h-6"
                    >
                      <path
                        stroke-linecap="round"
                        stroke-linejoin="round"
                        d="M17.25 8.25 21 12m0 0-3.75 3.75M21 12H3"
                      />
                    </svg>
                  </button>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>

      <div class="flex mx-6">
        <h1 class="border border-yellow-400 border-9 mt-6"></h1>
        <p class="text-white ml-4 mt-6 lg:text-2xl text-lg font-bold">
          Histoires en cours
        </p>
      </div>
      <div class="mt-4 lg:lg:mx-16 mx-6 mx-6 overflow-x-auto">
        <div class="flex space-x-4">
          <div class="transition-shadow duration-300 text-gray-700 rounded">
            <div class="relative">
              <router-link to="/romandetail"
                ><div class="overflow-hidden">
                  <img
                    src="../assets/17117.jpeg"
                    class="object-cover w-full rounded transform transition duration-300 hover:scale-105"
                    alt=""
                  /></div
              ></router-link>
              <span
                class="absolute top-0 left-0 text-xl font-bold text-white px-2 py-1 rounded-full"
                ><svg
                  xmlns="http://www.w3.org/2000/svg"
                  fill="none"
                  viewBox="0 0 24 24"
                  stroke-width="1.5"
                  stroke="currentColor"
                  class="w-6 h-6 ml-4 mt-2"
                >
                  <path
                    stroke-linecap="round"
                    stroke-linejoin="round"
                    d="M21 8.25c0-2.485-2.099-4.5-4.688-4.5-1.935 0-3.597 1.126-4.312 2.733-.715-1.607-2.377-2.733-4.313-2.733C5.1 3.75 3 5.765 3 8.25c0 7.22 9 12 9 12s9-4.78 9-12Z"
                  />
                </svg>
              </span>
            </div>
            <div class="py-5">
              <p class="lg:text-2xl text-lg font-bold leading-5 text-blue-400">
                Le secret du rois
              </p>
              <p class="text-xl mt-2 leading-5 text-gray-600">
                Cette histoire divulge, en effet le manque ou la mefiance entre
                ... <span class="text-blue-400">Lire la suite</span>
              </p>
              <div class="flex mt-2">
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  fill="none"
                  viewBox="0 0 24 24"
                  stroke-width="1.5"
                  stroke="currentColor"
                  class="w-6 h-6 text-gray-200"
                >
                  <path
                    stroke-linecap="round"
                    stroke-linejoin="round"
                    d="M2.036 12.322a1.012 1.012 0 0 1 0-.639C3.423 7.51 7.36 4.5 12 4.5c4.638 0 8.573 3.007 9.963 7.178.07.207.07.431 0 .639C20.577 16.49 16.64 19.5 12 19.5c-4.638 0-8.573-3.007-9.963-7.178Z"
                  />
                  <path
                    stroke-linecap="round"
                    stroke-linejoin="round"
                    d="M15 12a3 3 0 1 1-6 0 3 3 0 0 1 6 0Z"
                  />
                </svg>
                <span class="text-gray-200 ml-3">400.00K</span>
                <button
                  class="text-yellow-400 font-bold px-4 rounded-full ml-4 border border-yellow-400"
                >
                  <spam class="text-sm">Humour</spam>
                </button>
              </div>
            </div>
          </div>
          <div class="transition-shadow duration-300 text-gray-700 rounded">
            <div class="relative">
              <router-link to="/romandetail"
                ><div class="overflow-hidden">
                  <img
                    src="../assets/17117.jpeg"
                    class="object-cover w-full rounded transform transition duration-300 hover:scale-105"
                    alt=""
                  /></div
              ></router-link>
              <span
                class="absolute top-0 left-0 text-xl font-bold text-white px-2 py-1 rounded-full"
                ><svg
                  xmlns="http://www.w3.org/2000/svg"
                  fill="none"
                  viewBox="0 0 24 24"
                  stroke-width="1.5"
                  stroke="currentColor"
                  class="w-6 h-6 ml-4 mt-2"
                >
                  <path
                    stroke-linecap="round"
                    stroke-linejoin="round"
                    d="M21 8.25c0-2.485-2.099-4.5-4.688-4.5-1.935 0-3.597 1.126-4.312 2.733-.715-1.607-2.377-2.733-4.313-2.733C5.1 3.75 3 5.765 3 8.25c0 7.22 9 12 9 12s9-4.78 9-12Z"
                  />
                </svg>
              </span>
            </div>
            <div class="py-5">
              <p class="lg:text-2xl text-lg font-bold leading-5 text-blue-400">
                Le secret du rois
              </p>
              <p class="text-xl mt-2 leading-5 text-gray-600">
                Cette histoire divulge, en effet le manque ou la mefiance entre
                ... <span class="text-blue-400">Lire la suite</span>
              </p>
              <div class="flex mt-2">
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  fill="none"
                  viewBox="0 0 24 24"
                  stroke-width="1.5"
                  stroke="currentColor"
                  class="w-6 h-6 text-gray-200"
                >
                  <path
                    stroke-linecap="round"
                    stroke-linejoin="round"
                    d="M2.036 12.322a1.012 1.012 0 0 1 0-.639C3.423 7.51 7.36 4.5 12 4.5c4.638 0 8.573 3.007 9.963 7.178.07.207.07.431 0 .639C20.577 16.49 16.64 19.5 12 19.5c-4.638 0-8.573-3.007-9.963-7.178Z"
                  />
                  <path
                    stroke-linecap="round"
                    stroke-linejoin="round"
                    d="M15 12a3 3 0 1 1-6 0 3 3 0 0 1 6 0Z"
                  />
                </svg>
                <span class="text-gray-200 ml-3">400.00K</span>
                <button
                  class="text-yellow-400 font-bold px-4 rounded-full ml-4 border border-yellow-400"
                >
                  <spam class="text-sm">Humour</spam>
                </button>
              </div>
            </div>
          </div>
          <div class="transition-shadow duration-300 text-gray-700 rounded">
            <div class="relative">
              <router-link to="/romandetail"
                ><div class="overflow-hidden">
                  <img
                    src="../assets/17117.jpeg"
                    class="object-cover w-full rounded transform transition duration-300 hover:scale-105"
                    alt=""
                  /></div
              ></router-link>
              <span
                class="absolute top-0 left-0 text-xl font-bold text-white px-2 py-1 rounded-full"
                ><svg
                  xmlns="http://www.w3.org/2000/svg"
                  fill="none"
                  viewBox="0 0 24 24"
                  stroke-width="1.5"
                  stroke="currentColor"
                  class="w-6 h-6 ml-4 mt-2"
                >
                  <path
                    stroke-linecap="round"
                    stroke-linejoin="round"
                    d="M21 8.25c0-2.485-2.099-4.5-4.688-4.5-1.935 0-3.597 1.126-4.312 2.733-.715-1.607-2.377-2.733-4.313-2.733C5.1 3.75 3 5.765 3 8.25c0 7.22 9 12 9 12s9-4.78 9-12Z"
                  />
                </svg>
              </span>
            </div>
            <div class="py-5">
              <p class="lg:text-2xl text-lg font-bold leading-5 text-blue-400">
                Le secret du rois
              </p>
              <p class="text-xl mt-2 leading-5 text-gray-600">
                Cette histoire divulge, en effet le manque ou la mefiance entre
                ... <span class="text-blue-400">Lire la suite</span>
              </p>
              <div class="flex mt-2">
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  fill="none"
                  viewBox="0 0 24 24"
                  stroke-width="1.5"
                  stroke="currentColor"
                  class="w-6 h-6 text-gray-200"
                >
                  <path
                    stroke-linecap="round"
                    stroke-linejoin="round"
                    d="M2.036 12.322a1.012 1.012 0 0 1 0-.639C3.423 7.51 7.36 4.5 12 4.5c4.638 0 8.573 3.007 9.963 7.178.07.207.07.431 0 .639C20.577 16.49 16.64 19.5 12 19.5c-4.638 0-8.573-3.007-9.963-7.178Z"
                  />
                  <path
                    stroke-linecap="round"
                    stroke-linejoin="round"
                    d="M15 12a3 3 0 1 1-6 0 3 3 0 0 1 6 0Z"
                  />
                </svg>
                <span class="text-gray-200 ml-3">400.00K</span>
                <button
                  class="text-yellow-400 font-bold px-4 rounded-full ml-4 border border-yellow-400"
                >
                  <spam class="text-sm">Humour</spam>
                </button>
              </div>
            </div>
          </div>
          <div class="transition-shadow duration-300 text-gray-700 rounded">
            <div class="relative">
              <router-link to="/romandetail"
                ><div class="overflow-hidden">
                  <img
                    src="../assets/17117.jpeg"
                    class="object-cover w-full rounded transform transition duration-300 hover:scale-105"
                    alt=""
                  /></div
              ></router-link>
              <span
                class="absolute top-0 left-0 text-xl font-bold text-white px-2 py-1 rounded-full"
                ><svg
                  xmlns="http://www.w3.org/2000/svg"
                  fill="none"
                  viewBox="0 0 24 24"
                  stroke-width="1.5"
                  stroke="currentColor"
                  class="w-6 h-6 ml-4 mt-2"
                >
                  <path
                    stroke-linecap="round"
                    stroke-linejoin="round"
                    d="M21 8.25c0-2.485-2.099-4.5-4.688-4.5-1.935 0-3.597 1.126-4.312 2.733-.715-1.607-2.377-2.733-4.313-2.733C5.1 3.75 3 5.765 3 8.25c0 7.22 9 12 9 12s9-4.78 9-12Z"
                  />
                </svg>
              </span>
            </div>
            <div class="py-5">
              <p class="lg:text-2xl text-lg font-bold leading-5 text-blue-400">
                Le secret du rois
              </p>
              <p class="text-xl mt-2 leading-5 text-gray-600">
                Cette histoire divulge, en effet le manque ou la mefiance entre
                ... <span class="text-blue-400">Lire la suite</span>
              </p>
              <div class="flex mt-2">
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  fill="none"
                  viewBox="0 0 24 24"
                  stroke-width="1.5"
                  stroke="currentColor"
                  class="w-6 h-6 text-gray-200"
                >
                  <path
                    stroke-linecap="round"
                    stroke-linejoin="round"
                    d="M2.036 12.322a1.012 1.012 0 0 1 0-.639C3.423 7.51 7.36 4.5 12 4.5c4.638 0 8.573 3.007 9.963 7.178.07.207.07.431 0 .639C20.577 16.49 16.64 19.5 12 19.5c-4.638 0-8.573-3.007-9.963-7.178Z"
                  />
                  <path
                    stroke-linecap="round"
                    stroke-linejoin="round"
                    d="M15 12a3 3 0 1 1-6 0 3 3 0 0 1 6 0Z"
                  />
                </svg>
                <span class="text-gray-200 ml-3">400.00K</span>
                <button
                  class="text-yellow-400 font-bold px-4 rounded-full ml-4 border border-yellow-400"
                >
                  <spam class="text-sm">Humour</spam>
                </button>
              </div>
            </div>
          </div>
          <div class="transition-shadow duration-300 text-gray-700 rounded">
            <div class="relative">
              <router-link to="/romandetail"
                ><div class="overflow-hidden">
                  <img
                    src="../assets/17117.jpeg"
                    class="object-cover w-full rounded transform transition duration-300 hover:scale-105"
                    alt=""
                  /></div
              ></router-link>
              <span
                class="absolute top-0 left-0 text-xl font-bold text-white px-2 py-1 rounded-full"
                ><svg
                  xmlns="http://www.w3.org/2000/svg"
                  fill="none"
                  viewBox="0 0 24 24"
                  stroke-width="1.5"
                  stroke="currentColor"
                  class="w-6 h-6 ml-4 mt-2"
                >
                  <path
                    stroke-linecap="round"
                    stroke-linejoin="round"
                    d="M21 8.25c0-2.485-2.099-4.5-4.688-4.5-1.935 0-3.597 1.126-4.312 2.733-.715-1.607-2.377-2.733-4.313-2.733C5.1 3.75 3 5.765 3 8.25c0 7.22 9 12 9 12s9-4.78 9-12Z"
                  />
                </svg>
              </span>
            </div>
            <div class="py-5">
              <p class="lg:text-2xl text-lg font-bold leading-5 text-blue-400">
                Le secret du rois
              </p>
              <p class="text-xl mt-2 leading-5 text-gray-600">
                Cette histoire divulge, en effet le manque ou la mefiance entre
                ... <span class="text-blue-400">Lire la suite</span>
              </p>
              <div class="flex mt-2">
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  fill="none"
                  viewBox="0 0 24 24"
                  stroke-width="1.5"
                  stroke="currentColor"
                  class="w-6 h-6 text-gray-200"
                >
                  <path
                    stroke-linecap="round"
                    stroke-linejoin="round"
                    d="M2.036 12.322a1.012 1.012 0 0 1 0-.639C3.423 7.51 7.36 4.5 12 4.5c4.638 0 8.573 3.007 9.963 7.178.07.207.07.431 0 .639C20.577 16.49 16.64 19.5 12 19.5c-4.638 0-8.573-3.007-9.963-7.178Z"
                  />
                  <path
                    stroke-linecap="round"
                    stroke-linejoin="round"
                    d="M15 12a3 3 0 1 1-6 0 3 3 0 0 1 6 0Z"
                  />
                </svg>
                <span class="text-gray-200 ml-3">400.00K</span>
                <button
                  class="text-yellow-400 font-bold px-4 rounded-full ml-4 border border-yellow-400"
                >
                  <spam class="text-sm">Humour</spam>
                </button>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div
        class="flex w-44 fond-bold rounded-full px-3 py-2 mx-auto mt-6 ml-auto text-white hover:text-gray-300"
        active-class="text-blue-500"
      >
        <div class="relative inline-block text-left text-lg">
          <button
            class="flex text-white hover:text-gray-300"
            active-class="text-white"
          >
            Afficher plus

            <svg
              xmlns="http://www.w3.org/2000/svg"
              class="w-6 h-6 ml-2"
              viewBox="0 0 20 20"
              fill="currentColor"
              aria-hidden="true"
            >
              <path
                fill-rule="evenodd"
                d="M6.293 9.293a1 1 0 011.414 0L10 11.586l2.293-2.293a1 1 0 111.414 1.414l-3 3a1 1 0 01-1.414 0l-3-3a1 1 0 010-1.414z"
                clip-rule="evenodd"
              />
            </svg>
          </button>
        </div>
      </div>
      <div class="bg-white">
        <div class="flex lg:mx-16 mx-6">
          <h1 class="border border-yellow-400 border-9 mt-6"></h1>
          <p class="text-black ml-4 mt-6 lg:text-2xl text-lg font-bold">
            Le top de mes genres préferées
          </p>
        </div>
        <div class="lg:mx-16 mx-6 flex mt-6 space-x-4 overflow-x-auto">
          <div class="shadow mb-6 py-2">
            <app-ranking-popular-genre-preview class="mt-4">
              <div
                class="ranking-popular-genre-preview-container first-preview even mx-2"
              >
                <div class="flex">
                  <h3 class="text-lg font-bold">Romance</h3>
                  <a
                    href="/explorer/stories/fantaisie-novel?language=FR"
                    class="text-gray-500 hover:underline ml-36"
                    >Voir plus</a
                  >
                </div>
                <div class="colNeovelFlexStart ranking-books-container">
                  <app-book-ranking-item class="fullWidth">
                    <a
                      href="/book/16042/FR/le-manoir-tome-1-le-prisonnier"
                      class="flex items-center space-x-2 mt-2"
                    >
                      <div class="relative">
                        <div class="img-hover-zoom w-24 h-24 overflow-hidden">
                          <img
                            src="../assets/17117.jpeg"
                            alt="Le Manoir - Tome 1 - Le prisonnier cover"
                            class="w-full h-full object-cover transform transition duration-300 hover:scale-105"
                          />
                        </div>
                        <span
                          class="absolute top-0 left-0 text-xl font-bold text-white px-2 py-1 rounded-full"
                          >1</span
                        >
                      </div>

                      <div class="colNeovelFlexStart book-info-container">
                        <h4 class="text-lg font-bold">Le secret du roi</h4>
                        <div class="flex mt-2">
                          <svg
                            xmlns="http://www.w3.org/2000/svg"
                            fill="none"
                            viewBox="0 0 24 24"
                            stroke-width="1.5"
                            stroke="currentColor"
                            class="w-6 h-6 text-black"
                          >
                            <path
                              stroke-linecap="round"
                              stroke-linejoin="round"
                              d="M2.036 12.322a1.012 1.012 0 0 1 0-.639C3.423 7.51 7.36 4.5 12 4.5c4.638 0 8.573 3.007 9.963 7.178.07.207.07.431 0 .639C20.577 16.49 16.64 19.5 12 19.5c-4.638 0-8.573-3.007-9.963-7.178Z"
                            />
                            <path
                              stroke-linecap="round"
                              stroke-linejoin="round"
                              d="M15 12a3 3 0 1 1-6 0 3 3 0 0 1 6 0Z"
                            />
                          </svg>
                          <span class="text-black ml-3">400.00K</span>
                          <button
                            class="text-black font-bold px-4 rounded-full ml-4"
                          >
                            <spam class="text-sm">Humour</spam>
                          </button>
                        </div>
                      </div>
                    </a>
                  </app-book-ranking-item>
                  <app-book-ranking-item class="fullWidth">
                    <a
                      href="/book/16042/FR/le-manoir-tome-1-le-prisonnier"
                      class="flex items-center space-x-2 mt-2"
                    >
                      <div class="relative">
                        <div class="img-hover-zoom w-24 h-24 overflow-hidden">
                          <img
                            src="../assets/17117.jpeg"
                            alt="Le Manoir - Tome 1 - Le prisonnier cover"
                            class="w-full h-full object-cover transform transition duration-300 hover:scale-105"
                          />
                        </div>
                        <span
                          class="absolute top-0 left-0 text-xl font-bold text-white px-2 py-1 rounded-full"
                          >2</span
                        >
                      </div>
                      <div class="colNeovelFlexStart book-info-container">
                        <h4 class="text-lg font-bold text-left">
                          Le secret du roi
                        </h4>
                        <div class="flex mt-2">
                          <svg
                            xmlns="http://www.w3.org/2000/svg"
                            fill="none"
                            viewBox="0 0 24 24"
                            stroke-width="1.5"
                            stroke="currentColor"
                            class="w-6 h-6 text-black"
                          >
                            <path
                              stroke-linecap="round"
                              stroke-linejoin="round"
                              d="M2.036 12.322a1.012 1.012 0 0 1 0-.639C3.423 7.51 7.36 4.5 12 4.5c4.638 0 8.573 3.007 9.963 7.178.07.207.07.431 0 .639C20.577 16.49 16.64 19.5 12 19.5c-4.638 0-8.573-3.007-9.963-7.178Z"
                            />
                            <path
                              stroke-linecap="round"
                              stroke-linejoin="round"
                              d="M15 12a3 3 0 1 1-6 0 3 3 0 0 1 6 0Z"
                            />
                          </svg>
                          <span class="text-black ml-3">400.00K</span>
                          <button
                            class="text-black font-bold px-4 rounded-full ml-4"
                          >
                            <spam class="text-sm">Humour</spam>
                          </button>
                        </div>
                      </div>
                    </a>
                  </app-book-ranking-item>
                  <app-book-ranking-item class="fullWidth">
                    <a
                      href="/book/16042/FR/le-manoir-tome-1-le-prisonnier"
                      class="flex items-center space-x-2 mt-2"
                    >
                      <div class="relative">
                        <div class="img-hover-zoom w-24 h-24 overflow-hidden">
                          <img
                            src="../assets/17117.jpeg"
                            alt="Le Manoir - Tome 1 - Le prisonnier cover"
                            class="w-full h-full object-cover transform transition duration-300 hover:scale-105"
                          />
                        </div>
                        <span
                          class="absolute top-0 left-0 text-xl font-bold text-white px-2 py-1 rounded-full"
                          >3</span
                        >
                      </div>
                      <div class="colNeovelFlexStart book-info-container">
                        <h4 class="text-lg font-bold text-left">
                          Le secret du roi
                        </h4>
                        <div class="flex mt-2">
                          <svg
                            xmlns="http://www.w3.org/2000/svg"
                            fill="none"
                            viewBox="0 0 24 24"
                            stroke-width="1.5"
                            stroke="currentColor"
                            class="w-6 h-6 text-black"
                          >
                            <path
                              stroke-linecap="round"
                              stroke-linejoin="round"
                              d="M2.036 12.322a1.012 1.012 0 0 1 0-.639C3.423 7.51 7.36 4.5 12 4.5c4.638 0 8.573 3.007 9.963 7.178.07.207.07.431 0 .639C20.577 16.49 16.64 19.5 12 19.5c-4.638 0-8.573-3.007-9.963-7.178Z"
                            />
                            <path
                              stroke-linecap="round"
                              stroke-linejoin="round"
                              d="M15 12a3 3 0 1 1-6 0 3 3 0 0 1 6 0Z"
                            />
                          </svg>
                          <span class="text-black ml-3">400.00K</span>
                          <button
                            class="text-black font-bold px-4 rounded-full ml-4"
                          >
                            <spam class="text-sm">Humour</spam>
                          </button>
                        </div>
                      </div>
                    </a>
                  </app-book-ranking-item>
                  <app-book-ranking-item class="fullWidth">
                    <a
                      href="/book/16042/FR/le-manoir-tome-1-le-prisonnier"
                      class="flex items-center space-x-2 mt-2"
                    >
                      <div class="relative">
                        <div class="img-hover-zoom w-24 h-24 overflow-hidden">
                          <img
                            src="../assets/17117.jpeg"
                            alt="Le Manoir - Tome 1 - Le prisonnier cover"
                            class="w-full h-full object-cover transform transition duration-300 hover:scale-105"
                          />
                        </div>
                        <span
                          class="absolute top-0 left-0 text-xl font-bold text-white px-2 py-1 rounded-full"
                          >4</span
                        >
                      </div>
                      <div class="colNeovelFlexStart book-info-container">
                        <h4 class="text-lg font-bold text-left">
                          Le secret du roi
                        </h4>
                        <div class="flex mt-2">
                          <svg
                            xmlns="http://www.w3.org/2000/svg"
                            fill="none"
                            viewBox="0 0 24 24"
                            stroke-width="1.5"
                            stroke="currentColor"
                            class="w-6 h-6 text-black"
                          >
                            <path
                              stroke-linecap="round"
                              stroke-linejoin="round"
                              d="M2.036 12.322a1.012 1.012 0 0 1 0-.639C3.423 7.51 7.36 4.5 12 4.5c4.638 0 8.573 3.007 9.963 7.178.07.207.07.431 0 .639C20.577 16.49 16.64 19.5 12 19.5c-4.638 0-8.573-3.007-9.963-7.178Z"
                            />
                            <path
                              stroke-linecap="round"
                              stroke-linejoin="round"
                              d="M15 12a3 3 0 1 1-6 0 3 3 0 0 1 6 0Z"
                            />
                          </svg>
                          <span class="text-black ml-3">400.00K</span>
                          <button
                            class="text-black font-bold px-4 rounded-full ml-4"
                          >
                            <spam class="text-sm">Humour</spam>
                          </button>
                        </div>
                      </div>
                    </a>
                  </app-book-ranking-item>
                  <app-book-ranking-item class="fullWidth">
                    <a
                      href="/book/16042/FR/le-manoir-tome-1-le-prisonnier"
                      class="flex items-center space-x-2 mt-2"
                    >
                      <div class="relative">
                        <div class="img-hover-zoom w-24 h-24 overflow-hidden">
                          <img
                            src="../assets/17117.jpeg"
                            alt="Le Manoir - Tome 1 - Le prisonnier cover"
                            class="w-full h-full object-cover transform transition duration-300 hover:scale-105"
                          />
                        </div>
                        <span
                          class="absolute top-0 left-0 text-xl font-bold text-white px-2 py-1 rounded-full"
                          >5</span
                        >
                      </div>
                      <div class="colNeovelFlexStart book-info-container">
                        <h4 class="text-lg font-bold text-left">
                          Le secret du roi
                        </h4>
                        <div class="flex mt-2">
                          <svg
                            xmlns="http://www.w3.org/2000/svg"
                            fill="none"
                            viewBox="0 0 24 24"
                            stroke-width="1.5"
                            stroke="currentColor"
                            class="w-6 h-6 text-black"
                          >
                            <path
                              stroke-linecap="round"
                              stroke-linejoin="round"
                              d="M2.036 12.322a1.012 1.012 0 0 1 0-.639C3.423 7.51 7.36 4.5 12 4.5c4.638 0 8.573 3.007 9.963 7.178.07.207.07.431 0 .639C20.577 16.49 16.64 19.5 12 19.5c-4.638 0-8.573-3.007-9.963-7.178Z"
                            />
                            <path
                              stroke-linecap="round"
                              stroke-linejoin="round"
                              d="M15 12a3 3 0 1 1-6 0 3 3 0 0 1 6 0Z"
                            />
                          </svg>
                          <span class="text-black ml-3">400.00K</span>
                          <button
                            class="text-black font-bold px-4 rounded-full ml-4"
                          >
                            <spam class="text-sm">Humour</spam>
                          </button>
                        </div>
                      </div>
                    </a>
                  </app-book-ranking-item>
                  <!-- Ajoutez d'autres éléments similaires ici -->
                </div>
              </div>
            </app-ranking-popular-genre-preview>
          </div>
          <div class="shadow mb-6 py-2">
            <app-ranking-popular-genre-preview class="mt-4">
              <div
                class="ranking-popular-genre-preview-container first-preview even mx-2"
              >
                <div class="flex">
                  <h3 class="text-lg font-bold">Romance</h3>
                  <a
                    href="/explorer/stories/fantaisie-novel?language=FR"
                    class="text-gray-500 hover:underline ml-36"
                    >Voir plus</a
                  >
                </div>
                <div class="colNeovelFlexStart ranking-books-container">
                  <app-book-ranking-item class="fullWidth">
                    <a
                      href="/book/16042/FR/le-manoir-tome-1-le-prisonnier"
                      class="flex items-center space-x-2 mt-2"
                    >
                      <div class="relative">
                        <div class="img-hover-zoom w-24 h-24 overflow-hidden">
                          <img
                            src="../assets/17117.jpeg"
                            alt="Le Manoir - Tome 1 - Le prisonnier cover"
                            class="w-full h-full object-cover transform transition duration-300 hover:scale-105"
                          />
                        </div>
                        <span
                          class="absolute top-0 left-0 text-xl font-bold text-white px-2 py-1 rounded-full"
                          >1</span
                        >
                      </div>
                      <div class="colNeovelFlexStart book-info-container">
                        <h4 class="text-lg font-bold">Le secret du roi</h4>
                        <div class="flex mt-2">
                          <svg
                            xmlns="http://www.w3.org/2000/svg"
                            fill="none"
                            viewBox="0 0 24 24"
                            stroke-width="1.5"
                            stroke="currentColor"
                            class="w-6 h-6 text-black"
                          >
                            <path
                              stroke-linecap="round"
                              stroke-linejoin="round"
                              d="M2.036 12.322a1.012 1.012 0 0 1 0-.639C3.423 7.51 7.36 4.5 12 4.5c4.638 0 8.573 3.007 9.963 7.178.07.207.07.431 0 .639C20.577 16.49 16.64 19.5 12 19.5c-4.638 0-8.573-3.007-9.963-7.178Z"
                            />
                            <path
                              stroke-linecap="round"
                              stroke-linejoin="round"
                              d="M15 12a3 3 0 1 1-6 0 3 3 0 0 1 6 0Z"
                            />
                          </svg>
                          <span class="text-black ml-3">400.00K</span>
                          <button
                            class="text-black font-bold px-4 rounded-full ml-4"
                          >
                            <spam class="text-sm">Humour</spam>
                          </button>
                        </div>
                      </div>
                    </a>
                  </app-book-ranking-item>
                  <app-book-ranking-item class="fullWidth">
                    <a
                      href="/book/16042/FR/le-manoir-tome-1-le-prisonnier"
                      class="flex items-center space-x-2 mt-2"
                    >
                      <div class="relative">
                        <div class="img-hover-zoom w-24 h-24 overflow-hidden">
                          <img
                            src="../assets/17117.jpeg"
                            alt="Le Manoir - Tome 1 - Le prisonnier cover"
                            class="w-full h-full object-cover transform transition duration-300 hover:scale-105"
                          />
                        </div>
                        <span
                          class="absolute top-0 left-0 text-xl font-bold text-white px-2 py-1 rounded-full"
                          >2</span
                        >
                      </div>
                      <div class="colNeovelFlexStart book-info-container">
                        <h4 class="text-lg font-bold text-left">
                          Le secret du roi
                        </h4>
                        <div class="flex mt-2">
                          <svg
                            xmlns="http://www.w3.org/2000/svg"
                            fill="none"
                            viewBox="0 0 24 24"
                            stroke-width="1.5"
                            stroke="currentColor"
                            class="w-6 h-6 text-black"
                          >
                            <path
                              stroke-linecap="round"
                              stroke-linejoin="round"
                              d="M2.036 12.322a1.012 1.012 0 0 1 0-.639C3.423 7.51 7.36 4.5 12 4.5c4.638 0 8.573 3.007 9.963 7.178.07.207.07.431 0 .639C20.577 16.49 16.64 19.5 12 19.5c-4.638 0-8.573-3.007-9.963-7.178Z"
                            />
                            <path
                              stroke-linecap="round"
                              stroke-linejoin="round"
                              d="M15 12a3 3 0 1 1-6 0 3 3 0 0 1 6 0Z"
                            />
                          </svg>
                          <span class="text-black ml-3">400.00K</span>
                          <button
                            class="text-black font-bold px-4 rounded-full ml-4"
                          >
                            <spam class="text-sm">Humour</spam>
                          </button>
                        </div>
                      </div>
                    </a>
                  </app-book-ranking-item>
                  <app-book-ranking-item class="fullWidth">
                    <a
                      href="/book/16042/FR/le-manoir-tome-1-le-prisonnier"
                      class="flex items-center space-x-2 mt-2"
                    >
                      <div class="relative">
                        <div class="img-hover-zoom w-24 h-24 overflow-hidden">
                          <img
                            src="../assets/17117.jpeg"
                            alt="Le Manoir - Tome 1 - Le prisonnier cover"
                            class="w-full h-full object-cover transform transition duration-300 hover:scale-105"
                          />
                        </div>
                        <span
                          class="absolute top-0 left-0 text-xl font-bold text-white px-2 py-1 rounded-full"
                          >3</span
                        >
                      </div>
                      <div class="colNeovelFlexStart book-info-container">
                        <h4 class="text-lg font-bold text-left">
                          Le secret du roi
                        </h4>
                        <div class="flex mt-2">
                          <svg
                            xmlns="http://www.w3.org/2000/svg"
                            fill="none"
                            viewBox="0 0 24 24"
                            stroke-width="1.5"
                            stroke="currentColor"
                            class="w-6 h-6 text-black"
                          >
                            <path
                              stroke-linecap="round"
                              stroke-linejoin="round"
                              d="M2.036 12.322a1.012 1.012 0 0 1 0-.639C3.423 7.51 7.36 4.5 12 4.5c4.638 0 8.573 3.007 9.963 7.178.07.207.07.431 0 .639C20.577 16.49 16.64 19.5 12 19.5c-4.638 0-8.573-3.007-9.963-7.178Z"
                            />
                            <path
                              stroke-linecap="round"
                              stroke-linejoin="round"
                              d="M15 12a3 3 0 1 1-6 0 3 3 0 0 1 6 0Z"
                            />
                          </svg>
                          <span class="text-black ml-3">400.00K</span>
                          <button
                            class="text-black font-bold px-4 rounded-full ml-4"
                          >
                            <spam class="text-sm">Humour</spam>
                          </button>
                        </div>
                      </div>
                    </a>
                  </app-book-ranking-item>
                  <app-book-ranking-item class="fullWidth">
                    <a
                      href="/book/16042/FR/le-manoir-tome-1-le-prisonnier"
                      class="flex items-center space-x-2 mt-2"
                    >
                      <div class="relative">
                        <div class="img-hover-zoom w-24 h-24 overflow-hidden">
                          <img
                            src="../assets/17117.jpeg"
                            alt="Le Manoir - Tome 1 - Le prisonnier cover"
                            class="w-full h-full object-cover transform transition duration-300 hover:scale-105"
                          />
                        </div>
                        <span
                          class="absolute top-0 left-0 text-xl font-bold text-white px-2 py-1 rounded-full"
                          >4</span
                        >
                      </div>
                      <div class="colNeovelFlexStart book-info-container">
                        <h4 class="text-lg font-bold text-left">
                          Le secret du roi
                        </h4>
                        <div class="flex mt-2">
                          <svg
                            xmlns="http://www.w3.org/2000/svg"
                            fill="none"
                            viewBox="0 0 24 24"
                            stroke-width="1.5"
                            stroke="currentColor"
                            class="w-6 h-6 text-black"
                          >
                            <path
                              stroke-linecap="round"
                              stroke-linejoin="round"
                              d="M2.036 12.322a1.012 1.012 0 0 1 0-.639C3.423 7.51 7.36 4.5 12 4.5c4.638 0 8.573 3.007 9.963 7.178.07.207.07.431 0 .639C20.577 16.49 16.64 19.5 12 19.5c-4.638 0-8.573-3.007-9.963-7.178Z"
                            />
                            <path
                              stroke-linecap="round"
                              stroke-linejoin="round"
                              d="M15 12a3 3 0 1 1-6 0 3 3 0 0 1 6 0Z"
                            />
                          </svg>
                          <span class="text-black ml-3">400.00K</span>
                          <button
                            class="text-black font-bold px-4 rounded-full ml-4"
                          >
                            <spam class="text-sm">Humour</spam>
                          </button>
                        </div>
                      </div>
                    </a>
                  </app-book-ranking-item>
                  <app-book-ranking-item class="fullWidth">
                    <a
                      href="/book/16042/FR/le-manoir-tome-1-le-prisonnier"
                      class="flex items-center space-x-2 mt-2"
                    >
                      <div class="relative">
                        <div class="img-hover-zoom w-24 h-24 overflow-hidden">
                          <img
                            src="../assets/17117.jpeg"
                            alt="Le Manoir - Tome 1 - Le prisonnier cover"
                            class="w-full h-full object-cover transform transition duration-300 hover:scale-105"
                          />
                        </div>
                        <span
                          class="absolute top-0 left-0 text-xl font-bold text-white px-2 py-1 rounded-full"
                          >5</span
                        >
                      </div>
                      <div class="colNeovelFlexStart book-info-container">
                        <h4 class="text-lg font-bold text-left">
                          Le secret du roi
                        </h4>
                        <div class="flex mt-2">
                          <svg
                            xmlns="http://www.w3.org/2000/svg"
                            fill="none"
                            viewBox="0 0 24 24"
                            stroke-width="1.5"
                            stroke="currentColor"
                            class="w-6 h-6 text-black"
                          >
                            <path
                              stroke-linecap="round"
                              stroke-linejoin="round"
                              d="M2.036 12.322a1.012 1.012 0 0 1 0-.639C3.423 7.51 7.36 4.5 12 4.5c4.638 0 8.573 3.007 9.963 7.178.07.207.07.431 0 .639C20.577 16.49 16.64 19.5 12 19.5c-4.638 0-8.573-3.007-9.963-7.178Z"
                            />
                            <path
                              stroke-linecap="round"
                              stroke-linejoin="round"
                              d="M15 12a3 3 0 1 1-6 0 3 3 0 0 1 6 0Z"
                            />
                          </svg>
                          <span class="text-black ml-3">400.00K</span>
                          <button
                            class="text-black font-bold px-4 rounded-full ml-4"
                          >
                            <spam class="text-sm">Humour</spam>
                          </button>
                        </div>
                      </div>
                    </a>
                  </app-book-ranking-item>
                  <!-- Ajoutez d'autres éléments similaires ici -->
                </div>
              </div>
            </app-ranking-popular-genre-preview>
          </div>
          <div class="shadow mb-6 py-2">
            <app-ranking-popular-genre-preview class="mt-4">
              <div
                class="ranking-popular-genre-preview-container first-preview even mx-2"
              >
                <div class="flex">
                  <h3 class="text-lg font-bold">Romance</h3>
                  <a
                    href="/explorer/stories/fantaisie-novel?language=FR"
                    class="text-gray-500 ml-36 hover:underline"
                    >Voir plus</a
                  >
                </div>
                <div class="colNeovelFlexStart ranking-books-container">
                  <app-book-ranking-item class="fullWidth">
                    <a
                      href="/book/16042/FR/le-manoir-tome-1-le-prisonnier"
                      class="flex items-center space-x-2 mt-2"
                    >
                      <div class="relative">
                        <div class="img-hover-zoom w-24 h-24 overflow-hidden">
                          <img
                            src="../assets/17117.jpeg"
                            alt="Le Manoir - Tome 1 - Le prisonnier cover"
                            class="w-full h-full object-cover transform transition duration-300 hover:scale-105"
                          />
                        </div>
                        <span
                          class="absolute top-0 left-0 text-xl font-bold text-white px-2 py-1 rounded-full"
                          >1</span
                        >
                      </div>
                      <div class="colNeovelFlexStart book-info-container">
                        <h4 class="text-lg font-bold text-left">
                          Le secret du roi
                        </h4>
                        <div class="flex mt-2">
                          <svg
                            xmlns="http://www.w3.org/2000/svg"
                            fill="none"
                            viewBox="0 0 24 24"
                            stroke-width="1.5"
                            stroke="currentColor"
                            class="w-6 h-6 text-black"
                          >
                            <path
                              stroke-linecap="round"
                              stroke-linejoin="round"
                              d="M2.036 12.322a1.012 1.012 0 0 1 0-.639C3.423 7.51 7.36 4.5 12 4.5c4.638 0 8.573 3.007 9.963 7.178.07.207.07.431 0 .639C20.577 16.49 16.64 19.5 12 19.5c-4.638 0-8.573-3.007-9.963-7.178Z"
                            />
                            <path
                              stroke-linecap="round"
                              stroke-linejoin="round"
                              d="M15 12a3 3 0 1 1-6 0 3 3 0 0 1 6 0Z"
                            />
                          </svg>
                          <span class="text-black ml-3">400.00K</span>
                          <button
                            class="text-black font-bold px-4 rounded-full ml-4"
                          >
                            <spam class="text-sm">Humour</spam>
                          </button>
                        </div>
                      </div>
                    </a>
                  </app-book-ranking-item>
                  <app-book-ranking-item class="fullWidth">
                    <a
                      href="/book/16042/FR/le-manoir-tome-1-le-prisonnier"
                      class="flex items-center space-x-2 mt-2"
                    >
                      <div class="relative">
                        <div class="img-hover-zoom w-24 h-24 overflow-hidden">
                          <img
                            src="../assets/17117.jpeg"
                            alt="Le Manoir - Tome 1 - Le prisonnier cover"
                            class="w-full h-full object-cover transform transition duration-300 hover:scale-105"
                          />
                        </div>
                        <span
                          class="absolute top-0 left-0 text-xl font-bold text-white px-2 py-1 rounded-full"
                          >2</span
                        >
                      </div>
                      <div class="colNeovelFlexStart book-info-container">
                        <h4 class="text-lg font-bold text-left">
                          Le secret du roi
                        </h4>
                        <div class="flex mt-2">
                          <svg
                            xmlns="http://www.w3.org/2000/svg"
                            fill="none"
                            viewBox="0 0 24 24"
                            stroke-width="1.5"
                            stroke="currentColor"
                            class="w-6 h-6 text-black"
                          >
                            <path
                              stroke-linecap="round"
                              stroke-linejoin="round"
                              d="M2.036 12.322a1.012 1.012 0 0 1 0-.639C3.423 7.51 7.36 4.5 12 4.5c4.638 0 8.573 3.007 9.963 7.178.07.207.07.431 0 .639C20.577 16.49 16.64 19.5 12 19.5c-4.638 0-8.573-3.007-9.963-7.178Z"
                            />
                            <path
                              stroke-linecap="round"
                              stroke-linejoin="round"
                              d="M15 12a3 3 0 1 1-6 0 3 3 0 0 1 6 0Z"
                            />
                          </svg>
                          <span class="text-black ml-3">400.00K</span>
                          <button
                            class="text-black font-bold px-4 rounded-full ml-4"
                          >
                            <spam class="text-sm">Humour</spam>
                          </button>
                        </div>
                      </div>
                    </a>
                  </app-book-ranking-item>
                  <app-book-ranking-item class="fullWidth">
                    <a
                      href="/book/16042/FR/le-manoir-tome-1-le-prisonnier"
                      class="flex items-center space-x-2 mt-2"
                    >
                      <div class="relative">
                        <div class="img-hover-zoom w-24 h-24 overflow-hidden">
                          <img
                            src="../assets/17117.jpeg"
                            alt="Le Manoir - Tome 1 - Le prisonnier cover"
                            class="w-full h-full object-cover transform transition duration-300 hover:scale-105"
                          />
                        </div>
                        <span
                          class="absolute top-0 left-0 text-xl font-bold text-white px-2 py-1 rounded-full"
                          >3</span
                        >
                      </div>
                      <div class="colNeovelFlexStart book-info-container">
                        <h4 class="text-lg font-bold text-left">
                          Le secret du roi
                        </h4>
                        <div class="flex mt-2">
                          <svg
                            xmlns="http://www.w3.org/2000/svg"
                            fill="none"
                            viewBox="0 0 24 24"
                            stroke-width="1.5"
                            stroke="currentColor"
                            class="w-6 h-6 text-black"
                          >
                            <path
                              stroke-linecap="round"
                              stroke-linejoin="round"
                              d="M2.036 12.322a1.012 1.012 0 0 1 0-.639C3.423 7.51 7.36 4.5 12 4.5c4.638 0 8.573 3.007 9.963 7.178.07.207.07.431 0 .639C20.577 16.49 16.64 19.5 12 19.5c-4.638 0-8.573-3.007-9.963-7.178Z"
                            />
                            <path
                              stroke-linecap="round"
                              stroke-linejoin="round"
                              d="M15 12a3 3 0 1 1-6 0 3 3 0 0 1 6 0Z"
                            />
                          </svg>
                          <span class="text-black ml-3">400.00K</span>
                          <button
                            class="text-black font-bold px-4 rounded-full ml-4"
                          >
                            <spam class="text-sm">Humour</spam>
                          </button>
                        </div>
                      </div>
                    </a>
                  </app-book-ranking-item>
                  <app-book-ranking-item class="fullWidth">
                    <a
                      href="/book/16042/FR/le-manoir-tome-1-le-prisonnier"
                      class="flex items-center space-x-2 mt-2"
                    >
                      <div class="relative">
                        <div class="img-hover-zoom w-24 h-24 overflow-hidden">
                          <img
                            src="../assets/17117.jpeg"
                            alt="Le Manoir - Tome 1 - Le prisonnier cover"
                            class="w-full h-full object-cover transform transition duration-300 hover:scale-105"
                          />
                        </div>
                        <span
                          class="absolute top-0 left-0 text-xl font-bold text-white px-2 py-1 rounded-full"
                          >4</span
                        >
                      </div>
                      <div class="colNeovelFlexStart book-info-container">
                        <h4 class="text-lg font-bold">Le secret du roi</h4>
                        <div class="flex mt-2">
                          <svg
                            xmlns="http://www.w3.org/2000/svg"
                            fill="none"
                            viewBox="0 0 24 24"
                            stroke-width="1.5"
                            stroke="currentColor"
                            class="w-6 h-6 text-black"
                          >
                            <path
                              stroke-linecap="round"
                              stroke-linejoin="round"
                              d="M2.036 12.322a1.012 1.012 0 0 1 0-.639C3.423 7.51 7.36 4.5 12 4.5c4.638 0 8.573 3.007 9.963 7.178.07.207.07.431 0 .639C20.577 16.49 16.64 19.5 12 19.5c-4.638 0-8.573-3.007-9.963-7.178Z"
                            />
                            <path
                              stroke-linecap="round"
                              stroke-linejoin="round"
                              d="M15 12a3 3 0 1 1-6 0 3 3 0 0 1 6 0Z"
                            />
                          </svg>
                          <span class="text-black ml-3">400.00K</span>
                          <button
                            class="text-black font-bold px-4 rounded-full ml-4"
                          >
                            <spam class="text-sm">Humour</spam>
                          </button>
                        </div>
                      </div>
                    </a>
                  </app-book-ranking-item>
                  <app-book-ranking-item class="fullWidth">
                    <a
                      href="/book/16042/FR/le-manoir-tome-1-le-prisonnier"
                      class="flex items-center space-x-2 mt-2"
                    >
                      <div class="relative">
                        <div class="img-hover-zoom w-24 h-24 overflow-hidden">
                          <img
                            src="../assets/17117.jpeg"
                            alt="Le Manoir - Tome 1 - Le prisonnier cover"
                            class="w-full h-full object-cover transform transition duration-300 hover:scale-105"
                          />
                        </div>
                        <span
                          class="absolute top-0 left-0 text-xl font-bold text-white px-2 py-1 rounded-full"
                          >5</span
                        >
                      </div>
                      <div class="colNeovelFlexStart book-info-container">
                        <h4 class="text-lg font-bold text-left">
                          Le secret du roi
                        </h4>
                        <div class="flex mt-2">
                          <svg
                            xmlns="http://www.w3.org/2000/svg"
                            fill="none"
                            viewBox="0 0 24 24"
                            stroke-width="1.5"
                            stroke="currentColor"
                            class="w-6 h-6 text-black"
                          >
                            <path
                              stroke-linecap="round"
                              stroke-linejoin="round"
                              d="M2.036 12.322a1.012 1.012 0 0 1 0-.639C3.423 7.51 7.36 4.5 12 4.5c4.638 0 8.573 3.007 9.963 7.178.07.207.07.431 0 .639C20.577 16.49 16.64 19.5 12 19.5c-4.638 0-8.573-3.007-9.963-7.178Z"
                            />
                            <path
                              stroke-linecap="round"
                              stroke-linejoin="round"
                              d="M15 12a3 3 0 1 1-6 0 3 3 0 0 1 6 0Z"
                            />
                          </svg>
                          <span class="text-black ml-3">400.00K</span>
                          <button
                            class="text-black font-bold px-4 rounded-full ml-4"
                          >
                            <spam class="text-sm">Humour</spam>
                          </button>
                        </div>
                      </div>
                    </a>
                  </app-book-ranking-item>
                  <!-- Ajoutez d'autres éléments similaires ici -->
                </div>
              </div>
            </app-ranking-popular-genre-preview>
          </div>
          <div class="shadow mb-6 py-2">
            <app-ranking-popular-genre-preview class="mt-4">
              <div
                class="ranking-popular-genre-preview-container first-preview even mx-2"
              >
                <div class="flex">
                  <h3 class="text-lg font-bold">Romance</h3>
                  <a
                    href="/explorer/stories/fantaisie-novel?language=FR"
                    class="text-gray-500 hover:underline ml-36"
                    >Voir plus</a
                  >
                </div>
                <div class="colNeovelFlexStart ranking-books-container">
                  <app-book-ranking-item class="fullWidth">
                    <a
                      href="/book/16042/FR/le-manoir-tome-1-le-prisonnier"
                      class="flex items-center space-x-2 mt-2"
                    >
                      <div class="relative">
                        <div class="img-hover-zoom w-24 h-24 overflow-hidden">
                          <img
                            src="../assets/17117.jpeg"
                            alt="Le Manoir - Tome 1 - Le prisonnier cover"
                            class="w-full h-full object-cover transform transition duration-300 hover:scale-105"
                          />
                        </div>
                        <span
                          class="absolute top-0 left-0 text-xl font-bold text-white px-2 py-1 rounded-full"
                          >1</span
                        >
                      </div>
                      <div class="colNeovelFlexStart book-info-container">
                        <h4 class="text-lg font-bold">Le secret du roi</h4>
                        <div class="flex mt-2">
                          <svg
                            xmlns="http://www.w3.org/2000/svg"
                            fill="none"
                            viewBox="0 0 24 24"
                            stroke-width="1.5"
                            stroke="currentColor"
                            class="w-6 h-6 text-black"
                          >
                            <path
                              stroke-linecap="round"
                              stroke-linejoin="round"
                              d="M2.036 12.322a1.012 1.012 0 0 1 0-.639C3.423 7.51 7.36 4.5 12 4.5c4.638 0 8.573 3.007 9.963 7.178.07.207.07.431 0 .639C20.577 16.49 16.64 19.5 12 19.5c-4.638 0-8.573-3.007-9.963-7.178Z"
                            />
                            <path
                              stroke-linecap="round"
                              stroke-linejoin="round"
                              d="M15 12a3 3 0 1 1-6 0 3 3 0 0 1 6 0Z"
                            />
                          </svg>
                          <span class="text-black ml-3">400.00K</span>
                          <button
                            class="text-black font-bold px-4 rounded-full ml-4"
                          >
                            <spam class="text-sm">Humour</spam>
                          </button>
                        </div>
                      </div>
                    </a>
                  </app-book-ranking-item>
                  <app-book-ranking-item class="fullWidth">
                    <a
                      href="/book/16042/FR/le-manoir-tome-1-le-prisonnier"
                      class="flex items-center space-x-2 mt-2"
                    >
                      <div class="relative">
                        <div class="img-hover-zoom w-24 h-24 overflow-hidden">
                          <img
                            src="../assets/17117.jpeg"
                            alt="Le Manoir - Tome 1 - Le prisonnier cover"
                            class="w-full h-full object-cover transform transition duration-300 hover:scale-105"
                          />
                        </div>
                        <span
                          class="absolute top-0 left-0 text-xl font-bold text-white px-2 py-1 rounded-full"
                          >2</span
                        >
                      </div>
                      <div class="colNeovelFlexStart book-info-container">
                        <h4 class="text-lg font-bold text-left">
                          Le secret du roi
                        </h4>
                        <div class="flex mt-2">
                          <svg
                            xmlns="http://www.w3.org/2000/svg"
                            fill="none"
                            viewBox="0 0 24 24"
                            stroke-width="1.5"
                            stroke="currentColor"
                            class="w-6 h-6 text-black"
                          >
                            <path
                              stroke-linecap="round"
                              stroke-linejoin="round"
                              d="M2.036 12.322a1.012 1.012 0 0 1 0-.639C3.423 7.51 7.36 4.5 12 4.5c4.638 0 8.573 3.007 9.963 7.178.07.207.07.431 0 .639C20.577 16.49 16.64 19.5 12 19.5c-4.638 0-8.573-3.007-9.963-7.178Z"
                            />
                            <path
                              stroke-linecap="round"
                              stroke-linejoin="round"
                              d="M15 12a3 3 0 1 1-6 0 3 3 0 0 1 6 0Z"
                            />
                          </svg>
                          <span class="text-black ml-3">400.00K</span>
                          <button
                            class="text-black font-bold px-4 rounded-full ml-4"
                          >
                            <spam class="text-sm">Humour</spam>
                          </button>
                        </div>
                      </div>
                    </a>
                  </app-book-ranking-item>
                  <app-book-ranking-item class="fullWidth">
                    <a
                      href="/book/16042/FR/le-manoir-tome-1-le-prisonnier"
                      class="flex items-center space-x-2 mt-2"
                    >
                      <div class="relative">
                        <div class="img-hover-zoom w-24 h-24 overflow-hidden">
                          <img
                            src="../assets/17117.jpeg"
                            alt="Le Manoir - Tome 1 - Le prisonnier cover"
                            class="w-full h-full object-cover transform transition duration-300 hover:scale-105"
                          />
                        </div>
                        <span
                          class="absolute top-0 left-0 text-xl font-bold text-white px-2 py-1 rounded-full"
                          >3</span
                        >
                      </div>
                      <div class="colNeovelFlexStart book-info-container">
                        <h4 class="text-lg font-bold text-left">
                          Le secret du roi
                        </h4>
                        <div class="flex mt-2">
                          <svg
                            xmlns="http://www.w3.org/2000/svg"
                            fill="none"
                            viewBox="0 0 24 24"
                            stroke-width="1.5"
                            stroke="currentColor"
                            class="w-6 h-6 text-black"
                          >
                            <path
                              stroke-linecap="round"
                              stroke-linejoin="round"
                              d="M2.036 12.322a1.012 1.012 0 0 1 0-.639C3.423 7.51 7.36 4.5 12 4.5c4.638 0 8.573 3.007 9.963 7.178.07.207.07.431 0 .639C20.577 16.49 16.64 19.5 12 19.5c-4.638 0-8.573-3.007-9.963-7.178Z"
                            />
                            <path
                              stroke-linecap="round"
                              stroke-linejoin="round"
                              d="M15 12a3 3 0 1 1-6 0 3 3 0 0 1 6 0Z"
                            />
                          </svg>
                          <span class="text-black ml-3">400.00K</span>
                          <button
                            class="text-black font-bold px-4 rounded-full ml-4"
                          >
                            <spam class="text-sm">Humour</spam>
                          </button>
                        </div>
                      </div>
                    </a>
                  </app-book-ranking-item>
                  <app-book-ranking-item class="fullWidth">
                    <a
                      href="/book/16042/FR/le-manoir-tome-1-le-prisonnier"
                      class="flex items-center space-x-2 mt-2"
                    >
                      <div class="relative">
                        <div class="img-hover-zoom w-24 h-24 overflow-hidden">
                          <img
                            src="../assets/17117.jpeg"
                            alt="Le Manoir - Tome 1 - Le prisonnier cover"
                            class="w-full h-full object-cover transform transition duration-300 hover:scale-105"
                          />
                        </div>
                        <span
                          class="absolute top-0 left-0 text-xl font-bold text-white px-2 py-1 rounded-full"
                          >4</span
                        >
                      </div>
                      <div class="colNeovelFlexStart book-info-container">
                        <h4 class="text-lg font-bold text-left">
                          Le secret du roi
                        </h4>
                        <div class="flex mt-2">
                          <svg
                            xmlns="http://www.w3.org/2000/svg"
                            fill="none"
                            viewBox="0 0 24 24"
                            stroke-width="1.5"
                            stroke="currentColor"
                            class="w-6 h-6 text-black"
                          >
                            <path
                              stroke-linecap="round"
                              stroke-linejoin="round"
                              d="M2.036 12.322a1.012 1.012 0 0 1 0-.639C3.423 7.51 7.36 4.5 12 4.5c4.638 0 8.573 3.007 9.963 7.178.07.207.07.431 0 .639C20.577 16.49 16.64 19.5 12 19.5c-4.638 0-8.573-3.007-9.963-7.178Z"
                            />
                            <path
                              stroke-linecap="round"
                              stroke-linejoin="round"
                              d="M15 12a3 3 0 1 1-6 0 3 3 0 0 1 6 0Z"
                            />
                          </svg>
                          <span class="text-black ml-3">400.00K</span>
                          <button
                            class="text-black font-bold px-4 rounded-full ml-4"
                          >
                            <spam class="text-sm">Humour</spam>
                          </button>
                        </div>
                      </div>
                    </a>
                  </app-book-ranking-item>
                  <app-book-ranking-item class="fullWidth">
                    <a
                      href="/book/16042/FR/le-manoir-tome-1-le-prisonnier"
                      class="flex items-center space-x-2 mt-2"
                    >
                      <div class="relative">
                        <div class="img-hover-zoom w-24 h-24 overflow-hidden">
                          <img
                            src="../assets/17117.jpeg"
                            alt="Le Manoir - Tome 1 - Le prisonnier cover"
                            class="w-full h-full object-cover transform transition duration-300 hover:scale-105"
                          />
                        </div>
                        <span
                          class="absolute top-0 left-0 text-xl font-bold text-white px-2 py-1 rounded-full"
                          >5</span
                        >
                      </div>
                      <div class="colNeovelFlexStart book-info-container">
                        <h4 class="text-lg font-bold text-left">
                          Le secret du roi
                        </h4>
                        <div class="flex mt-2">
                          <svg
                            xmlns="http://www.w3.org/2000/svg"
                            fill="none"
                            viewBox="0 0 24 24"
                            stroke-width="1.5"
                            stroke="currentColor"
                            class="w-6 h-6 text-black"
                          >
                            <path
                              stroke-linecap="round"
                              stroke-linejoin="round"
                              d="M2.036 12.322a1.012 1.012 0 0 1 0-.639C3.423 7.51 7.36 4.5 12 4.5c4.638 0 8.573 3.007 9.963 7.178.07.207.07.431 0 .639C20.577 16.49 16.64 19.5 12 19.5c-4.638 0-8.573-3.007-9.963-7.178Z"
                            />
                            <path
                              stroke-linecap="round"
                              stroke-linejoin="round"
                              d="M15 12a3 3 0 1 1-6 0 3 3 0 0 1 6 0Z"
                            />
                          </svg>
                          <span class="text-black ml-3">400.00K</span>
                          <button
                            class="text-black font-bold px-4 rounded-full ml-4"
                          >
                            <spam class="text-sm">Humour</spam>
                          </button>
                        </div>
                      </div>
                    </a>
                  </app-book-ranking-item>
                  <!-- Ajoutez d'autres éléments similaires ici -->
                </div>
              </div>
            </app-ranking-popular-genre-preview>
          </div>
        </div>
      </div>
      <div class="flex mx-6">
        <h1 class="border border-yellow-400 border-9 mt-6"></h1>
        <p class="text-white ml-4 mt-6 lg:text-2xl text-lg font-bold">
          Tout le monde en parle !
        </p>
      </div>
      <div class="mt-4 lg:lg:mx-16 mx-6 mx-6 overflow-x-auto">
        <div class="flex space-x-4">
          <div class="transition-shadow duration-300 text-gray-700 rounded">
            <div class="relative">
              <router-link to="/romandetail"
                ><div class="overflow-hidden">
                  <img
                    src="../assets/17117.jpeg"
                    class="object-cover w-full rounded transform transition duration-300 hover:scale-105"
                    alt=""
                  /></div
              ></router-link>
              <span
                class="absolute top-0 left-0 text-xl font-bold text-white px-2 py-1 rounded-full"
                ><svg
                  xmlns="http://www.w3.org/2000/svg"
                  fill="none"
                  viewBox="0 0 24 24"
                  stroke-width="1.5"
                  stroke="currentColor"
                  class="w-6 h-6 ml-4 mt-2"
                >
                  <path
                    stroke-linecap="round"
                    stroke-linejoin="round"
                    d="M21 8.25c0-2.485-2.099-4.5-4.688-4.5-1.935 0-3.597 1.126-4.312 2.733-.715-1.607-2.377-2.733-4.313-2.733C5.1 3.75 3 5.765 3 8.25c0 7.22 9 12 9 12s9-4.78 9-12Z"
                  />
                </svg>
              </span>
            </div>
            <div class="py-5">
              <p class="lg:text-2xl text-lg font-bold leading-5 text-blue-400">
                Le secret du rois
              </p>
              <p class="text-xl mt-2 leading-5 text-gray-600">
                Cette histoire divulge, en effet le manque ou la mefiance entre
                ... <span class="text-blue-400">Lire la suite</span>
              </p>
              <div class="flex mt-2">
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  fill="none"
                  viewBox="0 0 24 24"
                  stroke-width="1.5"
                  stroke="currentColor"
                  class="w-6 h-6 text-gray-200"
                >
                  <path
                    stroke-linecap="round"
                    stroke-linejoin="round"
                    d="M2.036 12.322a1.012 1.012 0 0 1 0-.639C3.423 7.51 7.36 4.5 12 4.5c4.638 0 8.573 3.007 9.963 7.178.07.207.07.431 0 .639C20.577 16.49 16.64 19.5 12 19.5c-4.638 0-8.573-3.007-9.963-7.178Z"
                  />
                  <path
                    stroke-linecap="round"
                    stroke-linejoin="round"
                    d="M15 12a3 3 0 1 1-6 0 3 3 0 0 1 6 0Z"
                  />
                </svg>
                <span class="text-gray-200 ml-3">400.00K</span>
                <button
                  class="text-yellow-400 font-bold px-4 rounded-full ml-4 border border-yellow-400"
                >
                  <spam class="text-sm">Humour</spam>
                </button>
              </div>
            </div>
          </div>
          <div class="transition-shadow duration-300 text-gray-700 rounded">
            <div class="relative">
              <router-link to="/romandetail"
                ><div class="overflow-hidden">
                  <img
                    src="../assets/17117.jpeg"
                    class="object-cover w-full rounded transform transition duration-300 hover:scale-105"
                    alt=""
                  /></div
              ></router-link>
              <span
                class="absolute top-0 left-0 text-xl font-bold text-white px-2 py-1 rounded-full"
                ><svg
                  xmlns="http://www.w3.org/2000/svg"
                  fill="none"
                  viewBox="0 0 24 24"
                  stroke-width="1.5"
                  stroke="currentColor"
                  class="w-6 h-6 ml-4 mt-2"
                >
                  <path
                    stroke-linecap="round"
                    stroke-linejoin="round"
                    d="M21 8.25c0-2.485-2.099-4.5-4.688-4.5-1.935 0-3.597 1.126-4.312 2.733-.715-1.607-2.377-2.733-4.313-2.733C5.1 3.75 3 5.765 3 8.25c0 7.22 9 12 9 12s9-4.78 9-12Z"
                  />
                </svg>
              </span>
            </div>
            <div class="py-5">
              <p class="lg:text-2xl text-lg font-bold leading-5 text-blue-400">
                Le secret du rois
              </p>
              <p class="text-xl mt-2 leading-5 text-gray-600">
                Cette histoire divulge, en effet le manque ou la mefiance entre
                ... <span class="text-blue-400">Lire la suite</span>
              </p>
              <div class="flex mt-2">
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  fill="none"
                  viewBox="0 0 24 24"
                  stroke-width="1.5"
                  stroke="currentColor"
                  class="w-6 h-6 text-gray-200"
                >
                  <path
                    stroke-linecap="round"
                    stroke-linejoin="round"
                    d="M2.036 12.322a1.012 1.012 0 0 1 0-.639C3.423 7.51 7.36 4.5 12 4.5c4.638 0 8.573 3.007 9.963 7.178.07.207.07.431 0 .639C20.577 16.49 16.64 19.5 12 19.5c-4.638 0-8.573-3.007-9.963-7.178Z"
                  />
                  <path
                    stroke-linecap="round"
                    stroke-linejoin="round"
                    d="M15 12a3 3 0 1 1-6 0 3 3 0 0 1 6 0Z"
                  />
                </svg>
                <span class="text-gray-200 ml-3">400.00K</span>
                <button
                  class="text-yellow-400 font-bold px-4 rounded-full ml-4 border border-yellow-400"
                >
                  <spam class="text-sm">Humour</spam>
                </button>
              </div>
            </div>
          </div>
          <div class="transition-shadow duration-300 text-gray-700 rounded">
            <div class="relative">
              <router-link to="/romandetail"
                ><div class="overflow-hidden">
                  <img
                    src="../assets/17117.jpeg"
                    class="object-cover w-full rounded transform transition duration-300 hover:scale-105"
                    alt=""
                  /></div
              ></router-link>
              <span
                class="absolute top-0 left-0 text-xl font-bold text-white px-2 py-1 rounded-full"
                ><svg
                  xmlns="http://www.w3.org/2000/svg"
                  fill="none"
                  viewBox="0 0 24 24"
                  stroke-width="1.5"
                  stroke="currentColor"
                  class="w-6 h-6 ml-4 mt-2"
                >
                  <path
                    stroke-linecap="round"
                    stroke-linejoin="round"
                    d="M21 8.25c0-2.485-2.099-4.5-4.688-4.5-1.935 0-3.597 1.126-4.312 2.733-.715-1.607-2.377-2.733-4.313-2.733C5.1 3.75 3 5.765 3 8.25c0 7.22 9 12 9 12s9-4.78 9-12Z"
                  />
                </svg>
              </span>
            </div>
            <div class="py-5">
              <p class="lg:text-2xl text-lg font-bold leading-5 text-blue-400">
                Le secret du rois
              </p>
              <p class="text-xl mt-2 leading-5 text-gray-600">
                Cette histoire divulge, en effet le manque ou la mefiance entre
                ... <span class="text-blue-400">Lire la suite</span>
              </p>
              <div class="flex mt-2">
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  fill="none"
                  viewBox="0 0 24 24"
                  stroke-width="1.5"
                  stroke="currentColor"
                  class="w-6 h-6 text-gray-200"
                >
                  <path
                    stroke-linecap="round"
                    stroke-linejoin="round"
                    d="M2.036 12.322a1.012 1.012 0 0 1 0-.639C3.423 7.51 7.36 4.5 12 4.5c4.638 0 8.573 3.007 9.963 7.178.07.207.07.431 0 .639C20.577 16.49 16.64 19.5 12 19.5c-4.638 0-8.573-3.007-9.963-7.178Z"
                  />
                  <path
                    stroke-linecap="round"
                    stroke-linejoin="round"
                    d="M15 12a3 3 0 1 1-6 0 3 3 0 0 1 6 0Z"
                  />
                </svg>
                <span class="text-gray-200 ml-3">400.00K</span>
                <button
                  class="text-yellow-400 font-bold px-4 rounded-full ml-4 border border-yellow-400"
                >
                  <spam class="text-sm">Humour</spam>
                </button>
              </div>
            </div>
          </div>
          <div class="transition-shadow duration-300 text-gray-700 rounded">
            <div class="relative">
              <router-link to="/romandetail"
                ><div class="overflow-hidden">
                  <img
                    src="../assets/17117.jpeg"
                    class="object-cover w-full rounded transform transition duration-300 hover:scale-105"
                    alt=""
                  /></div
              ></router-link>
              <span
                class="absolute top-0 left-0 text-xl font-bold text-white px-2 py-1 rounded-full"
                ><svg
                  xmlns="http://www.w3.org/2000/svg"
                  fill="none"
                  viewBox="0 0 24 24"
                  stroke-width="1.5"
                  stroke="currentColor"
                  class="w-6 h-6 ml-4 mt-2"
                >
                  <path
                    stroke-linecap="round"
                    stroke-linejoin="round"
                    d="M21 8.25c0-2.485-2.099-4.5-4.688-4.5-1.935 0-3.597 1.126-4.312 2.733-.715-1.607-2.377-2.733-4.313-2.733C5.1 3.75 3 5.765 3 8.25c0 7.22 9 12 9 12s9-4.78 9-12Z"
                  />
                </svg>
              </span>
            </div>
            <div class="py-5">
              <p class="lg:text-2xl text-lg font-bold leading-5 text-blue-400">
                Le secret du rois
              </p>
              <p class="text-xl mt-2 leading-5 text-gray-600">
                Cette histoire divulge, en effet le manque ou la mefiance entre
                ... <span class="text-blue-400">Lire la suite</span>
              </p>
              <div class="flex mt-2">
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  fill="none"
                  viewBox="0 0 24 24"
                  stroke-width="1.5"
                  stroke="currentColor"
                  class="w-6 h-6 text-gray-200"
                >
                  <path
                    stroke-linecap="round"
                    stroke-linejoin="round"
                    d="M2.036 12.322a1.012 1.012 0 0 1 0-.639C3.423 7.51 7.36 4.5 12 4.5c4.638 0 8.573 3.007 9.963 7.178.07.207.07.431 0 .639C20.577 16.49 16.64 19.5 12 19.5c-4.638 0-8.573-3.007-9.963-7.178Z"
                  />
                  <path
                    stroke-linecap="round"
                    stroke-linejoin="round"
                    d="M15 12a3 3 0 1 1-6 0 3 3 0 0 1 6 0Z"
                  />
                </svg>
                <span class="text-gray-200 ml-3">400.00K</span>
                <button
                  class="text-yellow-400 font-bold px-4 rounded-full ml-4 border border-yellow-400"
                >
                  <spam class="text-sm">Humour</spam>
                </button>
              </div>
            </div>
          </div>
          <div class="transition-shadow duration-300 text-gray-700 rounded">
            <div class="relative">
              <router-link to="/romandetail"
                ><div class="overflow-hidden">
                  <img
                    src="../assets/17117.jpeg"
                    class="object-cover w-full rounded transform transition duration-300 hover:scale-105"
                    alt=""
                  /></div
              ></router-link>
              <span
                class="absolute top-0 left-0 text-xl font-bold text-white px-2 py-1 rounded-full"
                ><svg
                  xmlns="http://www.w3.org/2000/svg"
                  fill="none"
                  viewBox="0 0 24 24"
                  stroke-width="1.5"
                  stroke="currentColor"
                  class="w-6 h-6 ml-4 mt-2"
                >
                  <path
                    stroke-linecap="round"
                    stroke-linejoin="round"
                    d="M21 8.25c0-2.485-2.099-4.5-4.688-4.5-1.935 0-3.597 1.126-4.312 2.733-.715-1.607-2.377-2.733-4.313-2.733C5.1 3.75 3 5.765 3 8.25c0 7.22 9 12 9 12s9-4.78 9-12Z"
                  />
                </svg>
              </span>
            </div>
            <div class="py-5">
              <p class="lg:text-2xl text-lg font-bold leading-5 text-blue-400">
                Le secret du rois
              </p>
              <p class="text-xl mt-2 leading-5 text-gray-600">
                Cette histoire divulge, en effet le manque ou la mefiance entre
                ... <span class="text-blue-400">Lire la suite</span>
              </p>
              <div class="flex mt-2">
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  fill="none"
                  viewBox="0 0 24 24"
                  stroke-width="1.5"
                  stroke="currentColor"
                  class="w-6 h-6 text-gray-200"
                >
                  <path
                    stroke-linecap="round"
                    stroke-linejoin="round"
                    d="M2.036 12.322a1.012 1.012 0 0 1 0-.639C3.423 7.51 7.36 4.5 12 4.5c4.638 0 8.573 3.007 9.963 7.178.07.207.07.431 0 .639C20.577 16.49 16.64 19.5 12 19.5c-4.638 0-8.573-3.007-9.963-7.178Z"
                  />
                  <path
                    stroke-linecap="round"
                    stroke-linejoin="round"
                    d="M15 12a3 3 0 1 1-6 0 3 3 0 0 1 6 0Z"
                  />
                </svg>
                <span class="text-gray-200 ml-3">400.00K</span>
                <button
                  class="text-yellow-400 font-bold px-4 rounded-full ml-4 border border-yellow-400"
                >
                  <spam class="text-sm">Humour</spam>
                </button>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div
        class="flex w-44 fond-bold rounded-full px-3 py-2 mx-auto mt-6 ml-auto text-white hover:text-gray-300"
        active-class="text-blue-500"
      >
        <div class="relative inline-block text-left text-lg">
          <button
            class="flex text-white hover:text-gray-300"
            active-class="text-white"
          >
            Afficher plus

            <svg
              xmlns="http://www.w3.org/2000/svg"
              class="w-6 h-6 ml-2"
              viewBox="0 0 20 20"
              fill="currentColor"
              aria-hidden="true"
            >
              <path
                fill-rule="evenodd"
                d="M6.293 9.293a1 1 0 011.414 0L10 11.586l2.293-2.293a1 1 0 111.414 1.414l-3 3a1 1 0 01-1.414 0l-3-3a1 1 0 010-1.414z"
                clip-rule="evenodd"
              />
            </svg>
          </button>
        </div>
      </div>
      <div class="h-4 bg-white"></div>
      <div class="h-6 bg-yellow-400"></div>
    </div>
  </div>
  <!-- Composant de modal -->
</template>

<script>
//import { VideoPlayer } from "@videojs-player/vue";
//import "video.js/dist/video-js.css";
import axios from "axios";
import TokenService from "../services/storage.service";
import ApiService from "../services/api.service";
import Noty from "noty";
import "noty/lib/noty.css";
import "noty/lib/themes/mint.css";
//import spiner from "../components/spiner.vue";
export default {
  components: {
    //spiner,
  },
  data() {
    return {
      form: {
        name: "",
        email: "",
        password: "password2024@",
        role_id: "21db4696-4e59-40fa-b009-4f8342428767",
        // local: null,
      },
      targetDate: new Date("2024-04-18T23:59:59"), // Remplacez par votre date cible
      days: 0,
      hours: 0,
      minutes: 0,
      seconds: 0,
      yourWidthVariable: "700%", // Ajoutez la valeur de la largeur souhaitée ici
      yourHeightVariable: "300%",
      isLoading: false,
      isDropdownOpenPrfiles: false,
      isDropdownOpen: false,
      isDropdownOpens: false,
    };
  },
  mounted() {
    this.updateCountdown();
    setInterval(this.updateCountdown, 1000);
    // Intégrer le script dans la section head
    //const script = document.createElement('script');
    //script.src = 'https://rtcdn.cincopa.com/libasync.js';
    //script.type = 'text/javascript';

    // Attacher le script à la section head du document
    // document.head.appendChild(script);
  },
  methods: {
    toggleDropdownsfils() {
      this.isDropdownOpenPrfiles = !this.isDropdownOpenPrfiles;
    },
    toggleDropdowns() {
      this.isDropdownOpens = !this.isDropdownOpens;
      this.isDropdownOpen = false;
    },
    updateCountdown() {
      const now = new Date();
      const difference = this.targetDate - now;

      this.days = Math.floor(difference / (1000 * 60 * 60 * 24));
      this.hours = Math.floor(
        (difference % (1000 * 60 * 60 * 24)) / (1000 * 60 * 60)
      );
      this.minutes = Math.floor((difference % (1000 * 60 * 60)) / (1000 * 60));
      this.seconds = Math.floor((difference % (1000 * 60)) / 1000);
    },
    async login() {
      try {
        this.isLoading = true;
        const response = await axios.post("/api/auth/register", this.form);
        if (response.status === 200) {
          TokenService.saveToken(response.data.access_token);
          ApiService.setHeader();
          this.isLoading = false;
          new Noty({
            type: "success",
            layout: "topRight",
            text: "Votre email a été ajouté avec succès. Vous serez notifié(e) lorsqu'il y aura des mises à jour.",
            timeout: 5000,
          }).show();
          this.profile();
          //this.profile();
          //this.role();
        }
      } catch (error) {
        this.isLoading = false;
        // Gestion des erreurs HTTP
        if (error.response && error.response.status === 422) {
          this.showAlert = true;
          this.alert.message = "Adresse e-mail ou mot de passe incorrect.";
        } else {
          this.showAlert = true;
          this.alert.message =
            "Quelque chose s'est mal passé. Merci d'essayer plus tard.";
        }

        setTimeout(() => {
          this.showAlert = false;
        }, 5000);
      }
    },
  },
};
</script>

<style scoped>
.custom-background {
  background-image: url("~@/assets/17117.jpeg");
  /* Remplacez 'votre-image.jpg' par le nom de votre image */

  /* Ajustez la taille de l'image */
  background-repeat: no-repeat;
  background-position: center center;
  /* Centrez l'image */
  /* Ajoutez d'autres styles Tailwind CSS ou CSS personnalisés au besoin */
}
.border-9 {
  border-width: 2px;
}
/* Ajoutez des styles personnalisés pour le slider ici */
.fade-enter-active,
.fade-leave-active {
  transition: opacity 1s;
}

.fade-enter,
.fade-leave-to {
  opacity: 0;
}
.taille {
  height: 67%;
  width: 93%;
}
.slider-item {
  animation: slide 15s linear infinite;
}

@keyframes slide {
  0%,
  100% {
    transform: translateX(0%);
  }

  25% {
    transform: translateX(-100%);
  }

  50% {
    transform: translateX(-200%);
  }

  75% {
    transform: translateX(-300%);
  }
}
.cincopa_responsive_wrapper {
  height: 60%;
  left: 0;
  position: absolute;
  top: 0;
  width: 60%;
}

/* Pour les écrans dont la largeur est inférieure à 768px (mobile), la hauteur est de 100% */
@media only screen and (max-width: 768px) {
  .cincopa_responsive_wrapper {
    height: 70%;
    left: 0;
    position: absolute;
    top: 0;
    width: 70%;
  }
}
.extra-bold {
  font-weight: 800 !important;
}
/* Pour les écrans dont la largeur est supérieure à 768px (PC), la hauteur est de 65% */
@media only screen and (min-width: 769px) {
  .cincopa_responsive_wrapper {
    height: 75%;
    left: 0;
    position: absolute;
    top: 0;
    width: 75%;
  }
}
</style>
