<template>
  <nav class="h-12 shadow" style="background: #001928">
    <div class="container flex relative items-center">
      <!-- Logo -->
      <div class="flex lg:bg-white h-12 w-36">
        <router-link to="/">
          <img
            src="../assets/AP.png"
            alt="Logo"
            class="h-12 w-32 lg:mt-0 p-2"
          />
        </router-link>
        <div class="bg-yellow-400 h-12 ml-4 w-4 hidden md:block">
          <p class="text-yellow-400">..</p>
        </div>
      </div>
      <!-- Burger menu for mobile -->
      <div class="flex block lg:hidden">
        <button @click="isOpen = !isOpen" class="">
          <svg
            xmlns="http://www.w3.org/2000/svg"
            fill="none"
            viewBox="0 0 24 24"
            stroke-width="1.5"
            stroke="currentColor"
            class="w-6 h-6 text-white"
          >
            <path
              stroke-linecap="round"
              stroke-linejoin="round"
              d="M3.75 6.75h16.5M3.75 12h16.5m-16.5 5.25h16.5"
            />
          </svg>
        </button>
        <p @click="toggDropdown">
          <router-link
            class="text-white hover:text-gray-300"
            active-class="text-white"
            to="#"
            ><svg
              xmlns="http://www.w3.org/2000/svg"
              fill="none"
              viewBox="0 0 24 24"
              stroke-width="1.5"
              stroke="currentColor"
              class="w-6 h-6 ml-6 mt-2"
            >
              <path
                stroke-linecap="round"
                stroke-linejoin="round"
                d="m21 21-5.197-5.197m0 0A7.5 7.5 0 1 0 5.196 5.196a7.5 7.5 0 0 0 10.607 10.607Z"
              />
            </svg>
          </router-link>
        </p>
        <p @click="toggDropdown">
          <router-link
            class="text-white hover:text-gray-300"
            active-class="text-white"
            to="#"
            ><svg
              xmlns="http://www.w3.org/2000/svg"
              fill="none"
              viewBox="0 0 24 24"
              stroke-width="1.5"
              stroke="currentColor"
              class="w-6 h-6 ml-4 mt-2"
            >
              <path
                stroke-linecap="round"
                stroke-linejoin="round"
                d="M2.25 3h1.386c.51 0 .955.343 1.087.835l.383 1.437M7.5 14.25a3 3 0 0 0-3 3h15.75m-12.75-3h11.218c1.121-2.3 2.1-4.684 2.924-7.138a60.114 60.114 0 0 0-16.536-1.84M7.5 14.25 5.106 5.272M6 20.25a.75.75 0 1 1-1.5 0 .75.75 0 0 1 1.5 0Zm12.75 0a.75.75 0 1 1-1.5 0 .75.75 0 0 1 1.5 0Z"
              />
            </svg>
          </router-link>
        </p>
        <p @click="toggDropdown">
          <router-link
            class="text-white hover:text-gray-300"
            active-class="text-white"
            to="#"
          >
            <svg
              xmlns="http://www.w3.org/2000/svg"
              fill="none"
              viewBox="0 0 24 24"
              stroke-width="1.5"
              stroke="currentColor"
              class="w-6 h-6 ml-4 mt-2"
            >
              <path
                stroke-linecap="round"
                stroke-linejoin="round"
                d="M14.857 17.082a23.848 23.848 0 0 0 5.454-1.31A8.967 8.967 0 0 1 18 9.75V9A6 6 0 0 0 6 9v.75a8.967 8.967 0 0 1-2.312 6.022c1.733.64 3.56 1.085 5.455 1.31m5.714 0a24.255 24.255 0 0 1-5.714 0m5.714 0a3 3 0 1 1-5.714 0"
              />
            </svg>
          </router-link>
        </p>

        <div
          class="bg-white px-4 py-1 text-white poppins rounded-lg ring-red-300 transform transition duration-700 mt-1 ml-2"
          @click="toggleDropdownsfil"
        >
          <button
            type="button"
            class="relative flex rounded-full text-sm"
            id="user-menu-button"
            aria-expanded="false"
            aria-haspopup="true"
          >
            <img
              class="rounded-full w-6 h-6"
              src="../assets/c972ce3d5e2f4ea3d8d0b83ef3423dd1.png"
              alt=""
            />

            <svg
              xmlns="http://www.w3.org/2000/svg"
              class="w-5 h-5 ml-2 -mr-1 text-black"
              viewBox="0 0 20 20"
              fill="currentColor"
              aria-hidden="true"
            >
              <path
                fill-rule="evenodd"
                d="M6.293 9.293a1 1 0 011.414 0L10 11.586l2.293-2.293a1 1 0 111.414 1.414l-3 3a1 1 0 01-1.414 0l-3-3a1 1 0 010-1.414z"
                clip-rule="evenodd"
              />
            </svg>
          </button>

          <!-- Dropdown menu -->
          <div
                v-show="isDropdownOpenPrfile"
                class="absolute right-0 z-10 mt-1 origin-top-right rounded-md bg-white w-48 py-1 shadow-xl ring-1 ring-black ring-opacity-5 focus:outline-none"
                role="menu"
                aria-orientation="vertical"
                aria-labelledby="user-menu-button"
                tabindex="-1"
              >
                <!-- Active: "bg-gray-100", Not Active: "" -->

                <router-link
                  class="block px-4 py-2 flex text-sm text-black font-bold"
                  role="menuitem"
                  tabindex="-1"
                  id="user-menu-item-1"
                  to="/profils"
                >
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    fill="none"
                    viewBox="0 0 24 24"
                    stroke-width="1.5"
                    stroke="currentColor"
                    class="w-6 h-6"
                  >
                    <path
                      stroke-linecap="round"
                      stroke-linejoin="round"
                      d="M17.982 18.725A7.488 7.488 0 0 0 12 15.75a7.488 7.488 0 0 0-5.982 2.975m11.963 0a9 9 0 1 0-11.963 0m11.963 0A8.966 8.966 0 0 1 12 21a8.966 8.966 0 0 1-5.982-2.275M15 9.75a3 3 0 1 1-6 0 3 3 0 0 1 6 0Z"
                    />
                  </svg>
                  <span
                    class="ml-2 text-blue-800 font-bold font-mono hover:text-blue-900"
                    >Profils</span
                  ></router-link
                >
                <h1
                  class="text-xl font-bold border border-yellow-400 ml-4"
                ></h1>
                <router-link
                  class="block px-4 py-2 flex text-sm text-black font-bold"
                  role="menuitem"
                  tabindex="-1"
                  id="user-menu-item-1"
                  to="/profils"
                >
                  <span
                    class="ml-2 text-blue-800 font-bold font-mono hover:text-blue-900"
                    >Mes lectures</span
                  ></router-link
                >

                <router-link
                  class="block px-4 py-2 flex text-sm text-black font-bold"
                  role="menuitem"
                  tabindex="-1"
                  id="user-menu-item-1"
                  to="/profils"
                >
                  <span
                    class="ml-2 text-blue-800 font-bold font-mono hover:text-blue-900"
                    >Favoris</span
                  ></router-link
                >

                <router-link
                  class="block px-4 py-2 flex text-sm text-black font-bold"
                  role="menuitem"
                  tabindex="-1"
                  id="user-menu-item-1"
                  to="/profils"
                >
                  <span
                    class="ml-2 text-blue-800 font-bold font-mono hover:text-blue-900"
                    >Paramètre</span
                  ></router-link
                >

                <router-link
                  class="block px-4 py-2 flex text-sm text-black font-bold"
                  role="menuitem"
                  tabindex="-1"
                  id="user-menu-item-1"
                  to="/profils"
                >
                  <span
                    class="ml-2 text-blue-800 font-bold font-mono hover:text-blue-900"
                    >Aide et support</span
                  ></router-link
                >

                <button
                  @click="logout()"
                  class="block px-4 py-2 flex text-sm mt-2"
                  role="menuitem"
                  tabindex="-1"
                  id="user-menu-item-2"
                >
                  <svg
                    width="15"
                    height="23"
                    viewBox="0 0 15 23"
                    fill="none"
                    xmlns="http://www.w3.org/2000/svg"
                    class="ml-2"
                  >
                    <path
                      fill-rule="evenodd"
                      clip-rule="evenodd"
                      d="M3.58739 1.80188L7.86025 1.13043C11.0808 0.624343 12.6911 0.371297 13.7414 1.26942C14.7917 2.16754 14.7917 3.79759 14.7917 7.0577V10.4995H8.53897L11.4059 6.91591L9.84414 5.66652L5.67747 10.8748L5.17772 11.4995L5.67747 12.1242L9.84414 17.3326L11.4059 16.0832L8.53897 12.4995H14.7917V15.9414C14.7917 19.2015 14.7917 20.8316 13.7414 21.7297C12.6911 22.6278 11.0808 22.3747 7.86025 21.8687L3.58739 21.1972C1.97466 20.9438 1.16829 20.8171 0.688319 20.2558C0.208344 19.6945 0.208344 18.8782 0.208344 17.2457V5.7534C0.208344 4.12087 0.208344 3.30461 0.688319 2.74332C1.16829 2.18203 1.97466 2.05531 3.58739 1.80188Z"
                      fill="#001928"
                    />
                  </svg>

                  <span
                    class="ml-2 text-blue-800 font-bold font-mono hover:text-blue-900"
                    >Déconnexion</span
                  >
                </button>
              </div>
        </div>
      </div>
      <div
        :class="isOpen ? 'flex' : 'hidden'"
        class="w-full absolute z-20 h-80 top-10 right-0 md:left-auto md:right-auto justify-center border-b-8 border-yellow-400"
        style="background: #013854"
      >
        <ul class="list-none text-white mt-9">
          <li @click="toggDropdown" class="flex items-center justify-center">
            <router-link
              class="text-white hover:text-gray-300"
              active-class=""
              to="/"
              >Accueil</router-link
            >
          </li>
          <li class="flex items-center justify-center mt-6">
            <div
              class="flex items-center justify-center text-white hover:text-gray-300 ml-4"
              active-class="text-blue-500"
            >
              <div
                class="relative inline-block text-left text-lg whitespace-nowrap"
              >
                <button
                  @click="toggleDropdowns"
                  class="flex text-white hover:text-gray-300 whitespace-nowrap"
                  active-class="text-white"
                >
                  Genres littéraires

                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    class="w-5 h-5 ml-2 -mr-1"
                    viewBox="0 0 20 20"
                    fill="currentColor"
                    aria-hidden="true"
                  >
                    <path
                      fill-rule="evenodd"
                      d="M6.293 9.293a1 1 0 011.414 0L10 11.586l2.293-2.293a1 1 0 111.414 1.414l-3 3a1 1 0 01-1.414 0l-3-3a1 1 0 010-1.414z"
                      clip-rule="evenodd"
                    />
                  </svg>
                </button>
                <div
                  v-if="isDropdownOpens"
                  id="dropdown-menu"
                  class="origin-top-right absolute right-0 mt-2 w-48 rounded-md shadow-lg bg-white ring-1 ring-black ring-opacity-5"
                >
                  <div
                    class="py-2 p-2"
                    role="menu"
                    aria-orientation="vertical"
                    aria-labelledby="dropdown-button"
                  >
                    <div
                      class=""
                      role="menu"
                      aria-orientation="vertical"
                      aria-labelledby="dropdown-button"
                    >
                      <router-link
                        class="text-black hover:text-black"
                        active-class="text-slate-950"
                        to="#"
                      >
                        romance
                      </router-link>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </li>
          <li class="flex items-center justify-center mt-6">
            <div
              class="flex items-center justify-center text-white hover:text-gray-300 ml-4"
              active-class="text-blue-500"
            >
              <div class="relative inline-block text-left text-lg">
                <button
                  @click="toggleDropdown"
                  class="flex text-white hover:text-gray-300"
                  active-class="text-white"
                >
                  Ecrire

                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    class="w-5 h-5 ml-2 -mr-1"
                    viewBox="0 0 20 20"
                    fill="currentColor"
                    aria-hidden="true"
                  >
                    <path
                      fill-rule="evenodd"
                      d="M6.293 9.293a1 1 0 011.414 0L10 11.586l2.293-2.293a1 1 0 111.414 1.414l-3 3a1 1 0 01-1.414 0l-3-3a1 1 0 010-1.414z"
                      clip-rule="evenodd"
                    />
                  </svg>
                </button>
                 <div
                v-if="isDropdownOpen"
                id="dropdown-menu"
                class="origin-top-right absolute right-0 mt-2 w-64 rounded-md shadow-lg bg-white ring-1 ring-black ring-opacity-5"
              >
                <div
                  class="p-2"
                  role="menu"
                  aria-orientation="vertical"
                  aria-labelledby="dropdown-button"
                >
                  <div
                    class=""
                    role="menu"
                    aria-orientation="vertical"
                    aria-labelledby="dropdown-button"
                  >
                    <router-link
                      class="text-black hover:text-black flex"
                      active-class="text-slate-950"
                      to="#"
                    >
                      <svg
                        width="16"
                        height="20"
                        viewBox="0 0 16 20"
                        fill="none"
                        xmlns="http://www.w3.org/2000/svg"
                        class="mt-1"
                      >
                        <path
                          fill-rule="evenodd"
                          clip-rule="evenodd"
                          d="M8 0V6.5C8.00002 6.87288 8.13892 7.23239 8.38962 7.50842C8.64032 7.78445 8.98484 7.9572 9.356 7.993L9.5 8H16V18C16.0002 18.5046 15.8096 18.9906 15.4665 19.3605C15.1234 19.7305 14.6532 19.9572 14.15 19.995L14 20H2C1.49542 20.0002 1.00943 19.8096 0.639452 19.4665C0.269471 19.1234 0.0428434 18.6532 0.00500021 18.15L1.00268e-07 18V2C-0.000159579 1.49542 0.190406 1.00943 0.533497 0.639452C0.876588 0.269471 1.34684 0.0428433 1.85 0.00500011L2 0H8ZM8 9.5C7.75507 9.50003 7.51866 9.58996 7.33563 9.75272C7.15259 9.91547 7.03566 10.1397 7.007 10.383L7 10.5V12H5.5C5.24512 12.0003 4.99997 12.0979 4.81463 12.2728C4.6293 12.4478 4.51776 12.687 4.50283 12.9414C4.48789 13.1958 4.57067 13.4464 4.73426 13.6418C4.89785 13.8373 5.1299 13.9629 5.383 13.993L5.5 14H7V15.5C7.00028 15.7549 7.09788 16 7.27285 16.1854C7.44782 16.3707 7.68695 16.4822 7.94139 16.4972C8.19584 16.5121 8.44638 16.4293 8.64183 16.2657C8.83729 16.1021 8.9629 15.8701 8.993 15.617L9 15.5V14H10.5C10.7549 13.9997 11 13.9021 11.1854 13.7272C11.3707 13.5522 11.4822 13.313 11.4972 13.0586C11.5121 12.8042 11.4293 12.5536 11.2657 12.3582C11.1021 12.1627 10.8701 12.0371 10.617 12.007L10.5 12H9V10.5C9 10.2348 8.89464 9.98043 8.70711 9.79289C8.51957 9.60536 8.26522 9.5 8 9.5ZM10 0.043C10.3234 0.11165 10.6247 0.259389 10.877 0.473L11 0.586L15.414 5C15.6483 5.23411 15.8208 5.52275 15.916 5.84L15.956 6H10V0.043Z"
                          fill="#F7D408"
                        />
                      </svg>

                      <span class="ml-2 text-blue-800 font-bold font-mono hover:text-blue-900">Créer une histoire</span>
                    </router-link>
                    <h1
                      class="text-4xl font-bold border border-yellow-400 mt-2"
                    ></h1>
                  </div>
                </div>
                <div class="p-2">
                  <router-link
                    class="text-black hover:text-black"
                    active-class="text-slate-950"
                    to="#"
                  >
                    <span class="ml-2 text-blue-800 font-bold font-mono hover:text-blue-900">Mes histoires</span>
                  </router-link>

                  <h1
                    class="text-4xl font-bold border border-gray-400 mt-4"
                  ></h1>
                </div>
                <div
                  class="p-2"
                  role="menu"
                  aria-orientation="vertical"
                  aria-labelledby="dropdown-button"
                >
                  <div
                    class=""
                    role="menu"
                    aria-orientation="vertical"
                    aria-labelledby="dropdown-button"
                  >
                    <router-link
                      class="text-black hover:text-black"
                      active-class="text-slate-950"
                      to="#"
                    >
                      <span class="ml-2 text-blue-800 font-bold font-mono hover:text-blue-900">Concours d'écriture</span>
                    </router-link>
                  </div>
                  <h1
                    class="text-4xl font-bold border border-yellow-400 mt-4"
                  ></h1>
                </div>
                <div
                  class="py-2 p-2"
                  role="menu"
                  aria-orientation="vertical"
                  aria-labelledby="dropdown-button"
                >
                  <div
                    class=""
                    role="menu"
                    aria-orientation="vertical"
                    aria-labelledby="dropdown-button"
                  >
                    <router-link
                      class="text-black hover:text-black flex"
                      active-class="text-slate-950"
                      to="#"
                    >
                      <svg
                        width="20"
                        height="20"
                        viewBox="0 0 20 20"
                        fill="none"
                        xmlns="http://www.w3.org/2000/svg"
                        class="mt-1"
                      >
                        <path
                          fill-rule="evenodd"
                          clip-rule="evenodd"
                          d="M10 5C10 2.2385 12.2385 0 15 0C17.7615 0 20 2.2385 20 5C20 7.7615 17.7615 10 15 10H10.5C10.3674 10 10.2402 9.94732 10.1464 9.85355C10.0527 9.75979 10 9.63261 10 9.5V5ZM1.889 2.5C1.52082 2.50066 1.1679 2.64721 0.907559 2.90756C0.647214 3.1679 0.500661 3.52082 0.5 3.889C0.5 12.5115 7.4885 19.5 16.111 19.5C16.4792 19.4993 16.8321 19.3528 17.0924 19.0924C17.3528 18.8321 17.4993 18.4792 17.5 18.111V15.009C17.4993 14.6408 17.3528 14.2879 17.0924 14.0276C16.8321 13.7672 16.4792 13.6207 16.111 13.62C15.0872 13.6209 14.0698 13.4587 13.097 13.1395C12.8528 13.0575 12.5905 13.0452 12.3398 13.1041C12.089 13.1631 11.8596 13.2908 11.6775 13.473L9.983 15.1675C7.80932 13.9781 6.02182 12.191 4.832 10.0175L6.527 8.3225C6.70874 8.14201 6.83715 7.91488 6.89811 7.66611C6.95907 7.41733 6.95021 7.15656 6.8725 6.9125L6.8715 6.909L6.8705 6.9055C6.55001 5.93215 6.38746 4.91376 6.389 3.889C6.38834 3.52082 6.24179 3.1679 5.98144 2.90756C5.7211 2.64721 5.36818 2.50066 5 2.5H1.889ZM14.5 7.5V5.5H12.5V4.5H14.5V2.5H15.5V4.5H17.5V5.5H15.5V7.5H14.5Z"
                          fill="#F7D408"
                        />
                      </svg>

                     <span class="ml-2 text-blue-800 font-bold font-mono hover:text-blue-900">Conseils d'écriture</span>
                    </router-link>
                  </div>
                </div>
              </div>
              </div>
            </div>
          </li>
          <li
            @click="toggDropdown"
            class="flex items-center justify-center mt-6"
          >
            <router-link
              class="text-white hover:text-white ml-4 whitespace-nowrap"
              active-class="text-white whitespace-nowrap"
              to="#"
              >Espace auteur</router-link
            >
          </li>
        </ul>
      </div>
      <!-- Menu -->
      <ul
        :class="{ hidden: !isMobileMenuOpen }"
        class="lg:flex lg:space-x-4 lg:text-lg ml-44"
      >
        <li @click="toggDropdown">
          <router-link class="text-white" to="/">
            <span
              style="text-decoration-color: rgb(250 204 21); margin-bottom: 2px"
              class="hover:underline"
              >Accueil</span
            ></router-link
          >
        </li>
        <li class="">
          <div
            class="flex items-center justify-center text-white hover:text-gray-300 ml-4 hover:text-yellow-400 hover:underline"
            active-class="text-blue-500"
          >
            <div
              class="relative inline-block text-left text-lg whitespace-nowrap"
            >
              <button
                @click="toggleDropdowns"
                class="flex text-white whitespace-nowrap"
                active-class="text-white"
              >
                Genres littéraires

                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  class="w-5 h-5 ml-2 -mr-1"
                  viewBox="0 0 20 20"
                  fill="currentColor"
                  aria-hidden="true"
                >
                  <path
                    fill-rule="evenodd"
                    d="M6.293 9.293a1 1 0 011.414 0L10 11.586l2.293-2.293a1 1 0 111.414 1.414l-3 3a1 1 0 01-1.414 0l-3-3a1 1 0 010-1.414z"
                    clip-rule="evenodd"
                  />
                </svg>
              </button>
              <div
                v-if="isDropdownOpens"
                id="dropdown-menu"
                class="origin-top-right absolute right-0 mt-2 w-48 rounded-md shadow-lg bg-white ring-1 ring-black ring-opacity-5"
              >
                <div
                  class="py-2 p-2"
                  role="menu"
                  aria-orientation="vertical"
                  aria-labelledby="dropdown-button"
                >
                  <div
                    class=""
                    role="menu"
                    aria-orientation="vertical"
                    aria-labelledby="dropdown-button"
                  >
                    <router-link
                      class="text-black hover:text-black"
                      active-class="text-slate-950"
                      to="#"
                    >
                      romance
                    </router-link>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </li>
        <li class="" @mouseleave="ClotoggleDropdown">
          <div
            class="flex items-center justify-center text-white ml-4 hover:text-yellow-400 hover:underline"
            active-class="text-blue-500"
          >
            <div class="relative inline-block text-left text-lg">
              <button
                @mouseover="toggleDropdown"
                class="flex text-white"
                active-class="text-white"
              >
                Ecrire

                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  class="w-5 h-5 ml-2 -mr-1"
                  viewBox="0 0 20 20"
                  fill="currentColor"
                  aria-hidden="true"
                  @mouseover="toggleDropdown"
                >
                  <path
                    fill-rule="evenodd"
                    d="M6.293 9.293a1 1 0 011.414 0L10 11.586l2.293-2.293a1 1 0 111.414 1.414l-3 3a1 1 0 01-1.414 0l-3-3a1 1 0 010-1.414z"
                    clip-rule="evenodd"
                  />
                </svg>
              </button>
              <div
                v-if="isDropdownOpen"
                id="dropdown-menu"
                class="origin-top-right absolute right-0 mt-1 w-64 rounded-md shadow-lg bg-white ring-1 ring-black ring-opacity-5"
              >
                <div
                  class="p-2"
                  role="menu"
                  aria-orientation="vertical"
                  aria-labelledby="dropdown-button"
                >
                  <div
                    class=""
                    role="menu"
                    aria-orientation="vertical"
                    aria-labelledby="dropdown-button"
                  >
                    <router-link
                      class="text-black hover:text-black flex"
                      active-class="text-slate-950"
                      to="#"
                    >
                      <svg
                        width="16"
                        height="20"
                        viewBox="0 0 16 20"
                        fill="none"
                        xmlns="http://www.w3.org/2000/svg"
                        class="mt-1"
                      >
                        <path
                          fill-rule="evenodd"
                          clip-rule="evenodd"
                          d="M8 0V6.5C8.00002 6.87288 8.13892 7.23239 8.38962 7.50842C8.64032 7.78445 8.98484 7.9572 9.356 7.993L9.5 8H16V18C16.0002 18.5046 15.8096 18.9906 15.4665 19.3605C15.1234 19.7305 14.6532 19.9572 14.15 19.995L14 20H2C1.49542 20.0002 1.00943 19.8096 0.639452 19.4665C0.269471 19.1234 0.0428434 18.6532 0.00500021 18.15L1.00268e-07 18V2C-0.000159579 1.49542 0.190406 1.00943 0.533497 0.639452C0.876588 0.269471 1.34684 0.0428433 1.85 0.00500011L2 0H8ZM8 9.5C7.75507 9.50003 7.51866 9.58996 7.33563 9.75272C7.15259 9.91547 7.03566 10.1397 7.007 10.383L7 10.5V12H5.5C5.24512 12.0003 4.99997 12.0979 4.81463 12.2728C4.6293 12.4478 4.51776 12.687 4.50283 12.9414C4.48789 13.1958 4.57067 13.4464 4.73426 13.6418C4.89785 13.8373 5.1299 13.9629 5.383 13.993L5.5 14H7V15.5C7.00028 15.7549 7.09788 16 7.27285 16.1854C7.44782 16.3707 7.68695 16.4822 7.94139 16.4972C8.19584 16.5121 8.44638 16.4293 8.64183 16.2657C8.83729 16.1021 8.9629 15.8701 8.993 15.617L9 15.5V14H10.5C10.7549 13.9997 11 13.9021 11.1854 13.7272C11.3707 13.5522 11.4822 13.313 11.4972 13.0586C11.5121 12.8042 11.4293 12.5536 11.2657 12.3582C11.1021 12.1627 10.8701 12.0371 10.617 12.007L10.5 12H9V10.5C9 10.2348 8.89464 9.98043 8.70711 9.79289C8.51957 9.60536 8.26522 9.5 8 9.5ZM10 0.043C10.3234 0.11165 10.6247 0.259389 10.877 0.473L11 0.586L15.414 5C15.6483 5.23411 15.8208 5.52275 15.916 5.84L15.956 6H10V0.043Z"
                          fill="#F7D408"
                        />
                      </svg>

                      <span class="ml-2 text-blue-800 font-bold font-mono hover:text-blue-900">Créer une histoire</span>
                    </router-link>
                    <h1
                      class="text-4xl font-bold border border-yellow-400 mt-2"
                    ></h1>
                  </div>
                </div>
                <div class="p-2">
                  <router-link
                    class="text-black hover:text-black"
                    active-class="text-slate-950"
                    to="#"
                  >
                    <span class="ml-2 text-blue-800 font-bold font-mono hover:text-blue-900">Mes histoires</span>
                  </router-link>

                  <h1
                    class="text-4xl font-bold border border-gray-400 mt-4"
                  ></h1>
                </div>
                <div
                  class="p-2"
                  role="menu"
                  aria-orientation="vertical"
                  aria-labelledby="dropdown-button"
                >
                  <div
                    class=""
                    role="menu"
                    aria-orientation="vertical"
                    aria-labelledby="dropdown-button"
                  >
                    <router-link
                      class="text-black hover:text-black"
                      active-class="text-slate-950"
                      to="#"
                    >
                      <span class="ml-2 text-blue-800 font-bold font-mono hover:text-blue-900">Concours d'écriture</span>
                    </router-link>
                  </div>
                  <h1
                    class="text-4xl font-bold border border-yellow-400 mt-4"
                  ></h1>
                </div>
                <div
                  class="py-2 p-2"
                  role="menu"
                  aria-orientation="vertical"
                  aria-labelledby="dropdown-button"
                >
                  <div
                    class=""
                    role="menu"
                    aria-orientation="vertical"
                    aria-labelledby="dropdown-button"
                  >
                    <router-link
                      class="text-black hover:text-black flex"
                      active-class="text-slate-950"
                      to="#"
                    >
                      <svg
                        width="20"
                        height="20"
                        viewBox="0 0 20 20"
                        fill="none"
                        xmlns="http://www.w3.org/2000/svg"
                        class="mt-1"
                      >
                        <path
                          fill-rule="evenodd"
                          clip-rule="evenodd"
                          d="M10 5C10 2.2385 12.2385 0 15 0C17.7615 0 20 2.2385 20 5C20 7.7615 17.7615 10 15 10H10.5C10.3674 10 10.2402 9.94732 10.1464 9.85355C10.0527 9.75979 10 9.63261 10 9.5V5ZM1.889 2.5C1.52082 2.50066 1.1679 2.64721 0.907559 2.90756C0.647214 3.1679 0.500661 3.52082 0.5 3.889C0.5 12.5115 7.4885 19.5 16.111 19.5C16.4792 19.4993 16.8321 19.3528 17.0924 19.0924C17.3528 18.8321 17.4993 18.4792 17.5 18.111V15.009C17.4993 14.6408 17.3528 14.2879 17.0924 14.0276C16.8321 13.7672 16.4792 13.6207 16.111 13.62C15.0872 13.6209 14.0698 13.4587 13.097 13.1395C12.8528 13.0575 12.5905 13.0452 12.3398 13.1041C12.089 13.1631 11.8596 13.2908 11.6775 13.473L9.983 15.1675C7.80932 13.9781 6.02182 12.191 4.832 10.0175L6.527 8.3225C6.70874 8.14201 6.83715 7.91488 6.89811 7.66611C6.95907 7.41733 6.95021 7.15656 6.8725 6.9125L6.8715 6.909L6.8705 6.9055C6.55001 5.93215 6.38746 4.91376 6.389 3.889C6.38834 3.52082 6.24179 3.1679 5.98144 2.90756C5.7211 2.64721 5.36818 2.50066 5 2.5H1.889ZM14.5 7.5V5.5H12.5V4.5H14.5V2.5H15.5V4.5H17.5V5.5H15.5V7.5H14.5Z"
                          fill="#F7D408"
                        />
                      </svg>

                     <span class="ml-2 text-blue-800 font-bold font-mono hover:text-blue-900">Conseils d'écriture</span>
                    </router-link>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </li>
        <li @click="toggDropdown">
          <router-link class="text-white ml-4 whitespace-nowrap" to="#"
            ><span
              style="text-decoration-color: rgb(250 204 21); margin-bottom: 2px"
              class="hover:underline"
              >Espace auteur</span
            ></router-link
          >
        </li>
        <li @click="toggDropdown">
          <router-link
            class="text-white hover:text-gray-300"
            active-class="text-white"
            to="#"
            ><svg
              xmlns="http://www.w3.org/2000/svg"
              fill="none"
              viewBox="0 0 24 24"
              stroke-width="1.5"
              stroke="currentColor"
              class="w-6 h-6 ml-16"
            >
              <path
                stroke-linecap="round"
                stroke-linejoin="round"
                d="m21 21-5.197-5.197m0 0A7.5 7.5 0 1 0 5.196 5.196a7.5 7.5 0 0 0 10.607 10.607Z"
              />
            </svg>
          </router-link>
        </li>
        <li @click="toggDropdown">
          <router-link
            class="text-white hover:text-gray-300"
            active-class="text-white"
            to="#"
            ><svg
              xmlns="http://www.w3.org/2000/svg"
              fill="none"
              viewBox="0 0 24 24"
              stroke-width="1.5"
              stroke="currentColor"
              class="w-6 h-6 ml-4"
            >
              <path
                stroke-linecap="round"
                stroke-linejoin="round"
                d="M2.25 3h1.386c.51 0 .955.343 1.087.835l.383 1.437M7.5 14.25a3 3 0 0 0-3 3h15.75m-12.75-3h11.218c1.121-2.3 2.1-4.684 2.924-7.138a60.114 60.114 0 0 0-16.536-1.84M7.5 14.25 5.106 5.272M6 20.25a.75.75 0 1 1-1.5 0 .75.75 0 0 1 1.5 0Zm12.75 0a.75.75 0 1 1-1.5 0 .75.75 0 0 1 1.5 0Z"
              />
            </svg>
          </router-link>
        </li>
        <li @click="toggDropdown">
          <router-link
            class="text-white hover:text-gray-300"
            active-class="text-white"
            to="#"
          >
            <svg
              xmlns="http://www.w3.org/2000/svg"
              fill="none"
              viewBox="0 0 24 24"
              stroke-width="1.5"
              stroke="currentColor"
              class="w-6 h-6 ml-4"
            >
              <path
                stroke-linecap="round"
                stroke-linejoin="round"
                d="M14.857 17.082a23.848 23.848 0 0 0 5.454-1.31A8.967 8.967 0 0 1 18 9.75V9A6 6 0 0 0 6 9v.75a8.967 8.967 0 0 1-2.312 6.022c1.733.64 3.56 1.085 5.455 1.31m5.714 0a24.255 24.255 0 0 1-5.714 0m5.714 0a3 3 0 1 1-5.714 0"
              />
            </svg>
          </router-link>
        </li>

        <!-- Ajoutez les autres éléments de menu ici -->

        <!-- Sign in -->
        <li class="hidden lg:block" @mouseleave="ClotoggleDropdownsfil">
          <div>
            <div
              class="bg-white px-4 py-1 text-white poppins rounded-lg ring-red-300 transform transition duration-700"
            >
              <button
                type="button"
                class="relative flex rounded-full text-sm"
                id="user-menu-button"
                aria-expanded="false"
                aria-haspopup="true"
                @mouseover="toggleDropdownsfil"
              >
                <img
                  class="rounded-full w-6 h-6"
                  src="../assets/c972ce3d5e2f4ea3d8d0b83ef3423dd1.png"
                  alt=""
                />
                <span class="ml-4 text-black whitespace-nowrap"
                  >Gagnon User</span
                >
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  class="w-5 h-5 ml-2 -mr-1 text-black"
                  viewBox="0 0 20 20"
                  fill="currentColor"
                  aria-hidden="true"
                >
                  <path
                    fill-rule="evenodd"
                    d="M6.293 9.293a1 1 0 011.414 0L10 11.586l2.293-2.293a1 1 0 111.414 1.414l-3 3a1 1 0 01-1.414 0l-3-3a1 1 0 010-1.414z"
                    clip-rule="evenodd"
                  />
                </svg>
              </button>

              <!-- Dropdown menu -->
              <div
                v-show="isDropdownOpenPrfile"
                class="absolute right-0 z-10 mt-1 origin-top-right rounded-md bg-white w-48 py-1 shadow-xl ring-1 ring-black ring-opacity-5 focus:outline-none"
                role="menu"
                aria-orientation="vertical"
                aria-labelledby="user-menu-button"
                tabindex="-1"
              >
                <!-- Active: "bg-gray-100", Not Active: "" -->

                <router-link
                  class="block px-4 py-2 flex text-sm text-black font-bold"
                  role="menuitem"
                  tabindex="-1"
                  id="user-menu-item-1"
                  to="/profils"
                >
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    fill="none"
                    viewBox="0 0 24 24"
                    stroke-width="1.5"
                    stroke="currentColor"
                    class="w-6 h-6"
                  >
                    <path
                      stroke-linecap="round"
                      stroke-linejoin="round"
                      d="M17.982 18.725A7.488 7.488 0 0 0 12 15.75a7.488 7.488 0 0 0-5.982 2.975m11.963 0a9 9 0 1 0-11.963 0m11.963 0A8.966 8.966 0 0 1 12 21a8.966 8.966 0 0 1-5.982-2.275M15 9.75a3 3 0 1 1-6 0 3 3 0 0 1 6 0Z"
                    />
                  </svg>
                  <span
                    class="ml-2 text-blue-800 font-bold font-mono hover:text-blue-900"
                    >Profils</span
                  ></router-link
                >
                <h1
                  class="text-xl font-bold border border-yellow-400 ml-4"
                ></h1>
                <router-link
                  class="block px-4 py-2 flex text-sm text-black font-bold"
                  role="menuitem"
                  tabindex="-1"
                  id="user-menu-item-1"
                  to="/profils"
                >
                  <span
                    class="ml-2 text-blue-800 font-bold font-mono hover:text-blue-900"
                    >Mes lectures</span
                  ></router-link
                >

                <router-link
                  class="block px-4 py-2 flex text-sm text-black font-bold"
                  role="menuitem"
                  tabindex="-1"
                  id="user-menu-item-1"
                  to="/profils"
                >
                  <span
                    class="ml-2 text-blue-800 font-bold font-mono hover:text-blue-900"
                    >Favoris</span
                  ></router-link
                >

                <router-link
                  class="block px-4 py-2 flex text-sm text-black font-bold"
                  role="menuitem"
                  tabindex="-1"
                  id="user-menu-item-1"
                  to="/profils"
                >
                  <span
                    class="ml-2 text-blue-800 font-bold font-mono hover:text-blue-900"
                    >Paramètre</span
                  ></router-link
                >

                <router-link
                  class="block px-4 py-2 flex text-sm text-black font-bold"
                  role="menuitem"
                  tabindex="-1"
                  id="user-menu-item-1"
                  to="/profils"
                >
                  <span
                    class="ml-2 text-blue-800 font-bold font-mono hover:text-blue-900"
                    >Aide et support</span
                  ></router-link
                >

                <button
                  @click="logout()"
                  class="block px-4 py-2 flex text-sm mt-2"
                  role="menuitem"
                  tabindex="-1"
                  id="user-menu-item-2"
                >
                  <svg
                    width="15"
                    height="23"
                    viewBox="0 0 15 23"
                    fill="none"
                    xmlns="http://www.w3.org/2000/svg"
                    class="ml-2"
                  >
                    <path
                      fill-rule="evenodd"
                      clip-rule="evenodd"
                      d="M3.58739 1.80188L7.86025 1.13043C11.0808 0.624343 12.6911 0.371297 13.7414 1.26942C14.7917 2.16754 14.7917 3.79759 14.7917 7.0577V10.4995H8.53897L11.4059 6.91591L9.84414 5.66652L5.67747 10.8748L5.17772 11.4995L5.67747 12.1242L9.84414 17.3326L11.4059 16.0832L8.53897 12.4995H14.7917V15.9414C14.7917 19.2015 14.7917 20.8316 13.7414 21.7297C12.6911 22.6278 11.0808 22.3747 7.86025 21.8687L3.58739 21.1972C1.97466 20.9438 1.16829 20.8171 0.688319 20.2558C0.208344 19.6945 0.208344 18.8782 0.208344 17.2457V5.7534C0.208344 4.12087 0.208344 3.30461 0.688319 2.74332C1.16829 2.18203 1.97466 2.05531 3.58739 1.80188Z"
                      fill="#001928"
                    />
                  </svg>

                  <span
                    class="ml-2 text-blue-800 font-bold font-mono hover:text-blue-900"
                    >Déconnexion</span
                  >
                </button>
              </div>
            </div>
          </div>
        </li>
      </ul>
    </div>
  </nav>
</template>

<script>
import axios from "axios";
import TokenService from "../services/storage.service";
//import ApiService from "../services/api.service";
export default {
  name: "Navbar",
  data() {
    return {
      isDropdownOpen: false,
      isDropdownOpens: false,
      isMobileMenuOpen: false,
      isDropdownOpenPrfile: false,
      isOpen: false,
      user: "",
      name: "",
      imageUrl: "",
    };
  },
  created() {
    this.profile();
  },
  methods: {
    toggleDropdownsfil() {
      this.isDropdownOpenPrfile = !this.isDropdownOpenPrfile;
      this.isDropdownOpens = false;
      this.isDropdownOpen = false;
    },
    ClotoggleDropdownsfil() {
      this.isDropdownOpenPrfile = false;
    },
    toggleDropdown() {
      this.isDropdownOpen = !this.isDropdownOpen;
      this.isDropdownOpens = false;
      this.isDropdownOpenPrfile = false;
    },
    ClotoggleDropdown() {
      this.isDropdownOpen = false;
    },
    toggDropdown() {
      this.isDropdownOpens = false;
      this.isDropdownOpen = false;
      this.isDropdownOpenPrfile = false;
    },
    toggleDropdowns() {
      this.isDropdownOpens = !this.isDropdownOpens;
      this.isDropdownOpen = false;
      this.isDropdownOpenPrfile = false;
    },
    toggleDrop() {
      this.isDropdownOpens = !this.isDropdownOpens;
      this.isOpen = !this.isOpen;
    },
    toggleMobileMenu() {
      this.isMobileMenuOpen = !this.isMobileMenuOpen;
    },
    closeMobileMenu() {
      this.isMobileMenuOpen = false;
    },
    async profile() {
      try {
        const response = await axios.get("/api/profile");
        if (response.data) {
          this.name = response.data.name;
          this.user = response.data.id;
          this.imageUrl = response.data.profile_photo_path;
          console.log(this.user);
        }
      } catch (error) {
        this.user = error.data;
        console.log(this.user);
      }
    },
    async logout() {
      try {
        const response = await axios.get("/api/logout");
        if (response.status === 204) {
          TokenService.removeToken();
          this.user = "";
        }
      } catch (error) {
        console.log(error);
      }
    },
  },
  mounted() {},
};
</script>
